import React, { useEffect, useState } from 'react';
import { Divide as Hamburger } from 'hamburger-react';
import { Link, useNavigate } from 'react-router-dom';
import type { Method } from 'axios';

import useValidateSession from '../../../../hooks/useValidateSession';
import { getAuthorizedHttpRequestConfig, useHttpMutation } from '../../../../utilities/http';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { useToastNotification, useUserNotification, useUserNotificationActions } from '../../../../hooks/notification';
import useShoppingCart from '../../../../hooks/shopping/useShoppingCart';
import { updateCart, removeItemFromCart, updateCartItemQuantity, clearCart } from '../../../../redux/reducers/cart';
import { logout } from '../../../../redux/reducers/authentication';

import { UserDropdownMenu } from './elements';
import QuickOrderCart from '../../../../components/Cart/QuickOrderCart';
import UserNotifications from '../../../../components/UserNotifications';

import type { AuthenticationType } from '../../../../@types/authentication';
import {
    UserNotificationContextType,
    UserNotificationType,
    UserNotificationTypes,
} from '../../../../@types/userNotification';
import { SYNC_TIMEOUT, queryClient } from '../../../../config/constants/app';

type AuthenticationPageLayoutHeaderType = {
    openCart: boolean;
    toggleCartHandler: () => void;
    toggleNavigation: () => void;
    navigationBarState: boolean;
    authenticatedUser: AuthenticationType;
    showGlobalAddButton: boolean;
    addSomethingNewHandler: (addSomethingNew: boolean) => void;
    setLoggingOutState: React.Dispatch<React.SetStateAction<boolean>>;
};

const AuthenticationPageLayoutHeader: React.FC<AuthenticationPageLayoutHeaderType> = ({
    openCart,
    toggleCartHandler,
    toggleNavigation,
    showGlobalAddButton,
    addSomethingNewHandler,
    navigationBarState,
    authenticatedUser,
    setLoggingOutState,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const toastNotification = useToastNotification();
    const { privileges: userPrivileges } = authenticatedUser;
    const validateSession = useValidateSession();

    const customMutation = useHttpMutation(['notifications']);

    const handleAddSomethingNew = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        addSomethingNewHandler(true);
    };

    const handleUserLogout = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        setLoggingOutState(true);

        validateSession(authenticatedUser).then((authorizationToken) => {
            const httpRequestConfig = {
                ...getAuthorizedHttpRequestConfig(authorizationToken, 'POST'),
                url: '/session/logout',
            };

            customMutation({
                httpRequestConfig,
                responseHandler: () => {
                    queryClient.cancelQueries().then(() => {
                        dispatch(logout());
                        setLoggingOutState(false);
                        setTimeout(() => {
                            navigate('/', { replace: true });
                        }, 350);
                    });
                },
                errorHandler: () => {
                    setLoggingOutState(false);
                },
            });
        });
    };

    const { shoppingCart } = useShoppingCart();
    const cartItemCount = shoppingCart.cartItems.reduce(
        (total, cartItem) => total + cartItem.itemQuantity / cartItem.itemMinimumSaleQuantity,
        0,
    );

    const handleRemoveItemFromCart = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const { cartItems } = shoppingCart;
        const itemId = event.currentTarget.dataset.supply;
        const itemCount = cartItems.length;
        const selectedCartItem = cartItems.find((cartItem) => cartItem.itemId === itemId);
        if (selectedCartItem) {
            dispatch(removeItemFromCart(selectedCartItem.itemId));
            toastNotification(`${selectedCartItem.itemName} removed from cart!`, 'success');
            if (itemCount > 1) {
                return;
            }
            toggleCartHandler();
        }
    };

    const handleUpdateCartItemQuantity = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const { cartItems } = shoppingCart;
        const itemId = event.currentTarget.dataset.supply;
        const qtyDirection = event.currentTarget.dataset.direction;
        const selectedCartItem = cartItems.find((cartItem) => cartItem.itemId === itemId);
        if (selectedCartItem && qtyDirection) {
            const { itemQuantity, itemMinimumSaleQuantity } = selectedCartItem;
            let updatedItemQuantity = itemQuantity;
            if (qtyDirection === 'decrease') {
                if (updatedItemQuantity < itemMinimumSaleQuantity * 2) {
                    handleRemoveItemFromCart(event);
                    return;
                }
                updatedItemQuantity -= itemMinimumSaleQuantity;
            } else {
                updatedItemQuantity += itemMinimumSaleQuantity;
            }

            dispatch(updateCartItemQuantity({ ...selectedCartItem, itemQuantity: updatedItemQuantity }));
        }
    };

    const handleClearCart = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        dispatch(clearCart());
        toastNotification('Cart emptied!', 'success');
        toggleCartHandler();
    };

    const handleDriverAndSiteChange = (optionName: string, optionValue: string) => {
        dispatch(updateCart({ ...shoppingCart, [optionName]: optionValue }));
    };

    const { addNotification } = useUserNotificationActions();
    const siteSelectOptions = useAppSelector((state) => state.siteOptions);

    const handleCheckout = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const { siteId, driverId, cartItems } = shoppingCart;
        const selectedSiteName = siteSelectOptions.find((site) => site.value === siteId)?.label;
        if (siteId.trim() === '' || !selectedSiteName) {
            toastNotification('Invalid delivery sites selected!', 'error');
            return;
        }

        if (driverId.trim() === '' && userPrivileges.supplies) {
            toastNotification('Invalid delivery driver selected!', 'error');
            return;
        }

        const orderId = shoppingCart.orderId ? shoppingCart.orderId.trim() : '';
        const httpMethod = orderId.trim() === '' ? 'POST' : 'PATCH';

        const updatedOrderData = {
            orderId,
            siteId,
            driverId,
            orderItems: cartItems,
            orderStatus: userPrivileges.supplies || userPrivileges.dispatch ? 2 : 1,
        };
        const actionButton = event.currentTarget;
        validateSession(authenticatedUser).then((authorizationToken) => {
            const httpRequestConfig = {
                ...getAuthorizedHttpRequestConfig(authorizationToken, httpMethod as Method),
                url: 'supply/order',
                data: updatedOrderData,
            };

            customMutation({
                actionButton,
                httpRequestConfig,
                responseHandler: (response) => {
                    if ('success' in response) {
                        queryClient.refetchQueries({ queryKey: ['supplies'] }).then(() => {
                            const successMessage = userPrivileges.delivery
                                ? 'submitted successfully'
                                : 'ready for collection';

                            if (userPrivileges.supplies || userPrivileges.dispatch) {
                                const notification = {
                                    notificationId: '',
                                    recipients: [driverId.trim()],
                                    notificationType: UserNotificationContextType.SUCCESS,
                                    notificationStatus: 1,
                                    notificationMessage: `New order for ${selectedSiteName} ${successMessage}!`,
                                    notificationDate: new Date().toString(),
                                };

                                addNotification(notification)
                                    .then(() => {
                                        dispatch(clearCart());
                                        toggleCartHandler();
                                        toastNotification(
                                            `New order for ${selectedSiteName} ${successMessage}!`,
                                            'success',
                                        );
                                    })
                                    .catch(() => {});
                            } else {
                                dispatch(clearCart());
                                toggleCartHandler();
                                toastNotification(`New order for ${selectedSiteName} ${successMessage}!`, 'success');
                            }
                            setTimeout(() => {
                                const redirectUrl = userPrivileges.delivery ? '/home' : '/home/orders';
                                navigate(redirectUrl, { replace: true });
                            }, 350);
                        });
                    }
                },
                errorHandler: (error) => {
                    toastNotification(error.message, 'error');
                },
            });
        });
    };

    const [syncUserNotifications, setSyncUserNotifications] = useState(true);
    useEffect(() => {
        const syncTimer = setInterval(() => {
            setSyncUserNotifications(true);
        }, SYNC_TIMEOUT);

        return () => {
            clearInterval(syncTimer);
        };
    }, []);

    const { memberId, teamId } = authenticatedUser;
    const [unreadNotifications, setUnreadNotifications] = useState<UserNotificationType[]>([]);
    const [userNotifications, setUserNotifications] = useState<UserNotificationTypes>({
        myNotifications: [],
        teamNotifications: [],
    });
    const { fetchNotifications, fetchingNotifications } = useUserNotification();
    useEffect(() => {
        if (fetchingNotifications) {
            return;
        }
        if (syncUserNotifications) {
            fetchNotifications().then((response) => {
                if ('records' in response) {
                    const myUpdatedNotifications: UserNotificationType[] = [];
                    const updatedTeamNotifications: UserNotificationType[] = [];

                    (response.records as UserNotificationType[]).forEach((item) => {
                        const memberFound = item.recipients.find((user) => user === memberId);
                        if (memberFound) {
                            myUpdatedNotifications.push(item);
                        }
                        const teamFound = item.recipients.find((user) => user === teamId);
                        if (teamFound) {
                            updatedTeamNotifications.push(item);
                        }
                    });

                    setUserNotifications({
                        myNotifications: myUpdatedNotifications,
                        teamNotifications: updatedTeamNotifications,
                    });
                }
            });
            setSyncUserNotifications(false);
        }
    }, [fetchNotifications, fetchingNotifications, syncUserNotifications]);

    useEffect(() => {
        const { myNotifications, teamNotifications } = userNotifications;
        const updatedUnreadNotifications = [...myNotifications.concat(teamNotifications)].filter(
            (notification) => notification.notificationStatus.toString() === 'UNREAD',
        );
        setUnreadNotifications(updatedUnreadNotifications);
    }, [userNotifications]);

    const [showNotifications, setShowNotifications] = useState(false);
    const toggleNotificationModal = () => {
        setShowNotifications((notificationModalOpen) => !notificationModalOpen);
    };

    const handleToggleNotificationModal = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        toggleNotificationModal();
    };

    const handleUpdateNotifications = () => {
        setSyncUserNotifications(true);
    };

    return (
        <>
            <header>
                <div className="header-inner">
                    <div className="header-left">
                        {!userPrivileges.supplies && userPrivileges.delivery ? null : (
                            <Hamburger
                                toggled={navigationBarState}
                                toggle={toggleNavigation}
                                label="Open menu"
                                size={28}
                                rounded
                            />
                        )}

                        <Link to="/home">
                            <i className="main-logo" style={{ width: 40, height: 40 }} />
                        </Link>
                    </div>
                    <div className="header-right">
                        {showGlobalAddButton ? (
                            <>
                                <div id="globalAddNewBtn" style={{ width: 120 }}>
                                    <button
                                        type="button"
                                        className="btn btn-success btn-block p-2 pl-3 pr-3"
                                        onClick={handleAddSomethingNew}
                                    >
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <i
                                                className="custom-icon icon"
                                                style={{ width: 20, height: 20, backgroundColor: '#fff' }}
                                                aria-label="Plus Circle"
                                            />
                                            <span className="ml-auto">Add New</span>
                                        </div>
                                    </button>
                                </div>
                                <div className="btn-divider" />
                            </>
                        ) : null}
                        <div>
                            <button
                                type="button"
                                className="profile-dropdown-toggle cart-toggle cart-count"
                                data-qty={unreadNotifications.length.toString()}
                                onClick={handleToggleNotificationModal}
                                disabled={fetchingNotifications}
                            >
                                <i
                                    className="custom-icon icon"
                                    style={{ width: 20, height: 20 }}
                                    aria-label={`Notification ${unreadNotifications.length > 0 ? 'on' : 'off'}`}
                                />
                            </button>
                        </div>
                        <div className="btn-divider" />
                        {userPrivileges.supplies || userPrivileges.dispatch || userPrivileges.shop ? (
                            <>
                                <div className="cart-toggle">
                                    <button
                                        type="button"
                                        className="profile-dropdown-toggle cart-toggle cart-count"
                                        data-qty={cartItemCount.toString()}
                                        onClick={toggleCartHandler}
                                    >
                                        <i
                                            className="custom-icon icon"
                                            style={{ width: 20, height: 20 }}
                                            aria-label="Shopping Cart"
                                        />
                                    </button>
                                </div>
                                <div className="btn-divider" />
                            </>
                        ) : null}

                        <UserDropdownMenu
                            userLogoutHandler={handleUserLogout}
                            authenticatedUser={{ ...authenticatedUser }}
                        />
                    </div>
                </div>
            </header>
            <QuickOrderCart
                cart={shoppingCart}
                authenticatedUser={authenticatedUser}
                openQuickOrderCart={openCart}
                closeQuickOrderCartHandler={toggleCartHandler}
                selectOptionUpdateHandler={handleDriverAndSiteChange}
                updateItemQuantityHandler={handleUpdateCartItemQuantity}
                removeItemFromCartHandler={handleRemoveItemFromCart}
                clearCartHandler={handleClearCart}
                checkoutHandler={handleCheckout}
            />
            <UserNotifications
                notifications={userNotifications}
                unreadNotifications={unreadNotifications}
                openNotificationModal={showNotifications}
                updatingNotifications={fetchingNotifications}
                updateNotificationHandler={handleUpdateNotifications}
                closeNotificationModalHandler={toggleNotificationModal}
            />
        </>
    );
};

export default AuthenticationPageLayoutHeader;
