import { useCallback } from 'react';
import { differenceInMinutes, parseISO, formatISO } from 'date-fns';

import { useAppSelector, useAppDispatch } from '../redux';
import { updateNotificationSync } from '../../redux/reducers/userNotification';
import { UserNotificationSyncPropType } from '../../@types/userNotification';

const browserNotificationsAvailable = 'Notification' in window;

const useShowBrowserNotification = () => {
    const dispatchReduxAction = useAppDispatch();
    const recentNotifications = useAppSelector((state) => state.userNotifications);

    const handleShowBrowserNotification = useCallback(
        async (notificationMessage: string) => {
            if (browserNotificationsAvailable) {
                let notificationPermissionGranted: boolean;
                if (Notification.permission === 'granted') {
                    notificationPermissionGranted = true;
                } else {
                    const permission = await Notification.requestPermission();
                    notificationPermissionGranted = permission === 'granted';
                }

                if (notificationPermissionGranted) {
                    const notificationObject = new Notification(`HSD Notification`, {
                        body: notificationMessage,
                        icon: '/android-icon-48xx48x.png',
                        vibrate: [200, 100, 200],
                    });

                    setTimeout(() => {
                        notificationObject.close();
                    }, 500000);
                }
            }
        },
        [browserNotificationsAvailable],
    );

    const getRecentNotification = useCallback(
        (notificationIndex: string) =>
            new Promise<UserNotificationSyncPropType>((resolve) => {
                const defaultRecentNotification = { updatedAt: formatISO(new Date()), notificationMessage: '' };
                if (notificationIndex in recentNotifications) {
                    const recentNotification = recentNotifications.notificationIndex as UserNotificationSyncPropType;
                    if (recentNotification) {
                        resolve(recentNotification);
                    } else {
                        resolve(defaultRecentNotification);
                    }
                } else {
                    resolve(defaultRecentNotification);
                }
            }),
        [recentNotifications],
    );

    return useCallback((notificationIndex: string, updatedNotificationMessage: string) => {
        if (notificationIndex.trim() === '' || updatedNotificationMessage.trim() === '') {
            return;
        }

        let showNotification = true;
        getRecentNotification(notificationIndex)
            .then((recentNotification) => {
                const { notificationMessage, updatedAt } = recentNotification;
                showNotification = notificationMessage.trim() !== updatedNotificationMessage.trim();
                if (notificationMessage.trim() === updatedNotificationMessage.trim()) {
                    const parsedDate = parseISO(updatedAt);
                    const minutesPassed = differenceInMinutes(new Date(), parsedDate);
                    showNotification = minutesPassed > 90;
                }

                if (showNotification) {
                    handleShowBrowserNotification(updatedNotificationMessage).then(() => {});
                }

                dispatchReduxAction(
                    updateNotificationSync({
                        syncId: notificationIndex,
                        notificationMessage: updatedNotificationMessage,
                    }),
                );
            })
            .catch(() => {});
    }, []);
};

export default useShowBrowserNotification;
