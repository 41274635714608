import React, { useRef, useState } from 'react';

type NumericInputTypes = React.HTMLProps<HTMLInputElement>;

const NumericInput: React.FC<NumericInputTypes> = (numericInputProps) => {
    const numericInputRef = useRef<HTMLInputElement | null>(null);
    const { onChange, defaultValue, min, max, step, className, required, label, name, placeholder, disabled } =
        numericInputProps;
    let defaultNumericValue = min ? (min as number) : 0.5;
    if (defaultValue) {
        defaultNumericValue = parseFloat(defaultValue as string);
    }
    const [numericValue, setNumericValue] = useState(defaultNumericValue);

    const changeValueHandler = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        numericInputRef?.current?.offsetParent?.classList.add('focused');
        const actionType = event.currentTarget.dataset.action;
        const currentQtyValue = numericValue;
        const changeQtyValue = step ? parseFloat(step as string) : 0.5;

        const minimumValue = typeof min === 'undefined' ? changeQtyValue : +min;

        let newQtyValue = 0;
        if (typeof actionType !== 'undefined') {
            event.preventDefault();
            if (actionType === 'increase') {
                newQtyValue = currentQtyValue + changeQtyValue;
            } else {
                newQtyValue = currentQtyValue > changeQtyValue ? currentQtyValue - changeQtyValue : minimumValue;
            }
        } /* else {
            newQtyValue = +event.target.value;
        } */
        setNumericValue(newQtyValue);
        if (window.HTMLInputElement && Object) {
            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement?.prototype,
                'value',
            )?.set;
            nativeInputValueSetter?.call(numericInputRef.current, newQtyValue);
            const changeEvent = new Event('input', { bubbles: true });
            numericInputRef?.current?.dispatchEvent(changeEvent);
            setTimeout(() => numericInputRef?.current?.offsetParent?.classList.remove('focused'), 100);
        }
    };
    const handleNumericKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const minValue = typeof min === 'undefined' ? 0.5 : min;
    const stepValue = typeof step === 'undefined' ? 0.5 : step;

    return (
        <div
            className={`form-group form-group-default numeric ${disabled ? 'disabled' : ''} ${
                required ? 'required' : ''
            }`}
        >
            <div>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                {label && label.trim().length > 0 && <label>{label}</label>}
                <input
                    type="text"
                    min={minValue}
                    step={stepValue}
                    max={max}
                    className={`${className} form-control`}
                    name={name}
                    defaultValue={defaultValue}
                    placeholder={placeholder || ''}
                    onFocus={(event) => event.target?.offsetParent?.classList.add('focused')}
                    onBlur={(event) => event.target?.offsetParent?.classList.remove('focused')}
                    onChange={onChange}
                    disabled={disabled}
                    required={required}
                    ref={numericInputRef}
                />
            </div>
            <div className="button-container">
                <div className="buttons">
                    <div
                        className={`numeric-increase ${max && numericValue === +max ? 'disabled' : ''} ${
                            disabled ? 'disabled' : ''
                        }`}
                        title="increase"
                        onClick={changeValueHandler}
                        data-action="increase"
                        onKeyDown={handleNumericKeyDown}
                        role="presentation"
                    >
                        <i className="custom-icon icon" aria-label="Chevron-Up" style={{ width: 20, height: 20 }} />
                    </div>
                    <div
                        className={`numeric-decrease ${numericValue === +minValue ? 'disabled' : ''}  ${
                            disabled ? 'disabled' : ''
                        }`}
                        title="decrease"
                        onClick={changeValueHandler}
                        data-action="decrease"
                        onKeyDown={handleNumericKeyDown}
                        role="presentation"
                    >
                        <i className="custom-icon icon" aria-label="Chevron-Down" style={{ width: 20, height: 20 }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NumericInput;
