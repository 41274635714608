import { useEffect, useState } from 'react';
import { ToastType } from 'react-hot-toast';
import useToastNotification from './useToastNotification';

type NotificationTypes = { toastMessage: string; toastType: ToastType } | null;
const useDebouncedToastNotification = () => {
    const [toastNotification, setToastNotification] = useState<NotificationTypes>(null);
    const showToastNotification = useToastNotification();

    useEffect(() => {
        if (!toastNotification) return;
        const notificationTimer = window.setTimeout(() => {
            showToastNotification(toastNotification.toastMessage, toastNotification.toastType as ToastType);
        }, 500);

        // eslint-disable-next-line consistent-return
        return () => clearTimeout(notificationTimer);
    }, [toastNotification, showToastNotification]);

    return { setToastNotification };
};

export default useDebouncedToastNotification;
