import { Method } from 'axios';

import { queryClient } from '../../config/constants/app';
import { useAppDispatch, useAppSelector } from '../redux';
import useSiteOptions from "./useSiteOptions";
import { deleteSiteOption } from "../../redux/reducers/sites";
import useValidateSession from '../useValidateSession';
import { getAuthorizedHttpRequestConfig, useHttpMutation } from '../../utilities/http';
import { useToastNotification } from '../notification';

import { SiteType, SiteStatusType } from '../../@types/sites/site';

const useSiteActions = () => {
    const reduxDispatch = useAppDispatch();
    const validateSession = useValidateSession();
    const { refreshSiteOptions } = useSiteOptions();
    const authenticatedUser = useAppSelector((state) => state.authenticatedUser);

    const customMutateFn = useHttpMutation(['siteActions']);
    const toastNotification = useToastNotification();
    const handleError = (error: Record<string, any>) => {
        toastNotification(error.message, 'error');
    };

    const addUpdateSite = (site: SiteType, actionButton?: HTMLButtonElement) =>
        new Promise<Record<string, any>>((resolve, reject) => {
            validateSession(authenticatedUser)
                .then((authorizationToken) => {
                    const { siteId } = site;
                    const httpMethod = siteId.trim() === '' ? 'POST' : 'PATCH';
                    const httpRequestConfig = {
                        ...getAuthorizedHttpRequestConfig(authorizationToken, httpMethod as Method),
                        url: '/site',
                        data: site,
                    };

                    customMutateFn({
                        httpRequestConfig,
                        actionButton,
                        responseHandler: (response) => {
                            resolve(response);
                            queryClient.invalidateQueries({ queryKey: ['sites'], type: 'all' }).then(() => {
                                refreshSiteOptions();
                            });
                        },
                        errorHandler: handleError,
                    });
                })
                .catch(() => {
                    reject(new Error('Invalid or expired session, please login.'));
                });
        });

    type ToggleSiteStatusType = { siteId: string; siteStatus: SiteStatusType };

    const toggleSiteStatus = (data: ToggleSiteStatusType, actionButton?: HTMLButtonElement) =>
        new Promise<Record<string, any>>((resolve, reject) => {
            validateSession(authenticatedUser)
                .then((authorizationToken) => {
                    const httpRequestConfig = {
                        ...getAuthorizedHttpRequestConfig(authorizationToken, 'PATCH'),
                        url: '/site/status',
                        data: { siteId: data.siteId, siteStatus: +data.siteStatus },
                    };

                    customMutateFn({
                        httpRequestConfig,
                        actionButton,
                        responseHandler: (response) => {
                            resolve(response);
                            queryClient.invalidateQueries({ queryKey: ['sites'], type: 'all' }).then(() => {
                                if (+data.siteStatus === 1) {
                                    refreshSiteOptions();
                                    return;
                                }

                                reduxDispatch(deleteSiteOption(data.siteId));
                            });
                        },
                        errorHandler: handleError,
                    });
                })
                .catch(() => {
                    reject(new Error('Invalid or expired session, please login.'));
                });
        });

    const deleteSite = (siteId: string, actionButton?: HTMLButtonElement) =>
        new Promise<Record<string, any>>((resolve, reject) => {
            validateSession(authenticatedUser)
                .then((authorizationToken) => {
                    const httpRequestConfig = {
                        ...getAuthorizedHttpRequestConfig(authorizationToken),
                        url: '/site',
                        data: { siteId },
                        method: 'DELETE' as Method,
                    };

                    customMutateFn({
                        httpRequestConfig,
                        actionButton,
                        responseHandler: (response) => {
                            resolve(response);
                            queryClient.invalidateQueries({ queryKey: ['sites'], type: 'all' }).then(() => {
                                reduxDispatch(deleteSiteOption(siteId));
                            });
                        },
                        errorHandler: handleError,
                    });
                })
                .catch(() => {
                    reject(new Error('Invalid or expired session, please login.'));
                });
        });

    return { addUpdateSite, deleteSite, toggleSiteStatus };
};

export default useSiteActions;
