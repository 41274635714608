import React from 'react';

import Button from '../../../Button';
import DataListItem from '../../../DataListItem';
import { CartItemType } from '../../../../@types/cart';
import { formatCartItemQuantity } from '../../../../utilities/cart/formatCartItemQuantity';

type CartListItemType = {
    cartItem: CartItemType;
    updateItemQuantityHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
    removeItemFromCartHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const CartListItem: React.FC<CartListItemType> = ({
    cartItem,
    updateItemQuantityHandler,
    removeItemFromCartHandler,
}) => (
    <DataListItem>
        <div className="header">
            <div className="col-9 title">
                <div>
                    <i className="custom-icon icon" aria-label="Tag" />
                </div>
                <div>
                    <h2>
                        {cartItem.itemName}
                        <small>{formatCartItemQuantity(cartItem)}</small>
                    </h2>
                </div>
            </div>
            <div className="col-3 action-col text-right">
                <Button
                    data-supply={cartItem.itemId}
                    data-direction="decrease"
                    onClick={updateItemQuantityHandler}
                    className={`btn-link no-text${
                        cartItem.itemQuantity === cartItem.itemMinimumSaleQuantity ? ' disabled' : ''
                    }`}
                    disabled={cartItem.itemQuantity === cartItem.itemMinimumSaleQuantity}
                >
                    <i
                        className={`custom-icon icon${
                            cartItem.itemQuantity === cartItem.itemMinimumSaleQuantity ? '' : ' bg-danger'
                        }`}
                        aria-label="Minus Circle"
                    />
                </Button>
                <Button
                    data-supply={cartItem.itemId}
                    data-direction="increase"
                    onClick={updateItemQuantityHandler}
                    className="btn-link no-text"
                >
                    <i className="custom-icon icon bg-success" aria-label="Plus Circle" />
                </Button>
                <span className="btn-divider light" />
                <Button
                    data-supply={cartItem.itemId}
                    onClick={removeItemFromCartHandler}
                    className="btn-link delete-btn no-text"
                >
                    <i className="custom-icon icon" aria-label="Trash" />
                </Button>
            </div>
        </div>
    </DataListItem>
);

export default CartListItem;
