import React from 'react';
import { Outlet, useOutletContext, Link } from 'react-router-dom';

import { FullPageProgressIndicator } from '../../../components/ProgressIndicator';
import { AuthenticationType } from '../../../@types/authentication';
import useAuthenticatedUser from '../../../hooks/useAuthenticatedUser';
import { APP_NAME } from '../../../config/constants/app';

const ErrorPageLayout = () => {
    const { authenticatedUser, validatingUser } = useAuthenticatedUser();
    document.title = `Page not found :: ${APP_NAME}`;

    if (validatingUser) {
        return <FullPageProgressIndicator />;
    }

    return (
        <div className="error-page">
            <div className="branding" style={{ zIndex: 9 }}>
                <Link to="/">
                    <i className="logo" style={{ width: 150 }} />
                </Link>
            </div>
            <div className="error-video">
                <video
                    autoPlay
                    muted
                    playsInline
                    src="/assets/video/e404_main_video.mp4"
                    poster="/assets/video/e404_main_video_cover.jpg"
                />
            </div>

            <div className="error-body-container">
                <Outlet context={{ authenticatedUser: { ...authenticatedUser } }} />
                <div className="error-footer">
                    Video courtesy of{' '}
                    <a href="https://www.disney.com/divadsanders" rel="noreferrer" target="_blank">
                        Disney
                    </a>
                </div>
            </div>
        </div>
    );
};

type ContextType = {
    authenticatedUser: AuthenticationType;
};

export function useErrorPageAuthenticatedUser() {
    return useOutletContext<ContextType>();
}

export default ErrorPageLayout;
