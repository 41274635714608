import { useCallback } from 'react';

import { useAppSelector } from '../redux';
import useValidateSession from '../useValidateSession';
import { queryClient, SYNC_TIMEOUT } from '../../config/constants/app';
import { getAuthorizedHttpRequestConfig, sendHttpRequest } from '../../utilities/http';

const useTeamMemberList = () => {
    const validateSession = useValidateSession();
    const authenticatedUser = useAppSelector((state) => state.authenticatedUser);

    const fetchingTeamMembers = queryClient.isFetching({ queryKey: ['teamMembers'] }) > 0;
    const fetchTeamMembers = useCallback((filterParams?: Record<string, any>) => {
        const getTeamMembers = () =>
            validateSession(authenticatedUser).then((authorizationToken) => {
                const httpRequestConfig = {
                    ...getAuthorizedHttpRequestConfig(authorizationToken.trim()),
                    url: '/team/member',
                    params: filterParams,
                };

                return sendHttpRequest(httpRequestConfig);
            });

        return queryClient.fetchQuery({
            queryKey: ['teamMembers', { params: filterParams }],
            queryFn: getTeamMembers,
            staleTime: SYNC_TIMEOUT
        });
    }, []);

    return { fetchTeamMembers, fetchingTeamMembers };
};

export default useTeamMemberList;
