import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import Redirect from '../../../routes/Redirect';
import useAuthenticatedUser from '../../../hooks/useAuthenticatedUser';
import { FullPageProgressIndicator } from '../../../components/ProgressIndicator';
import { AuthenticatedPageLayoutHeader, AuthenticatedPageLayoutSidebar } from './components';

import { AuthenticationType } from '../../../@types/authentication';

const AuthenticatedPageLayout = () => {
    const mainContainer = useRef<HTMLDivElement | null>(null);
    const [navigationBarOpen, setNavigationBarOpen] = useState(false);
    const toggleNavigationBar = () => {
        if (mainContainer.current) {
            mainContainer.current?.classList.toggle('sidebar-open');
        }

        setNavigationBarOpen((prevState) => !prevState);
    };

    const [addSomethingNew, setAddSomethingNew] = useState(false);
    const [showGlobalAddNewButton, setShowAddNewButton] = useState(false);
    useEffect(() => {
        const addSomethingNewTimer = setTimeout(() => {
            setAddSomethingNew(false);
        }, 1000);

        return () => {
            clearTimeout(addSomethingNewTimer);
        };
    }, [addSomethingNew]);

    const handleClickAddSomethingNew = (addNew: boolean) => {
        setAddSomethingNew(addNew);
    };
    const [openCart, setOpenCart] = useState(false);
    const toggleCart = () => {
        setOpenCart((cartOpen) => !cartOpen);
    };
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const { authenticatedUser, validatingUser } = useAuthenticatedUser();
    if (validatingUser) {
        return <FullPageProgressIndicator />;
    }

    if (authenticatedUser.authenticationToken) {
        return (
            <div className="page-container" ref={mainContainer}>
                {isLoggingOut ? <FullPageProgressIndicator style={{ opacity: 0.75 }} /> : null}
                {/* Default navigation */}
                <AuthenticatedPageLayoutSidebar
                    authenticatedUser={{ ...authenticatedUser }}
                    navigationBarOpen={navigationBarOpen}
                    toggleNavigation={toggleNavigationBar}
                />
                <div className="page-content-container">
                    <AuthenticatedPageLayoutHeader
                        openCart={openCart}
                        toggleCartHandler={toggleCart}
                        toggleNavigation={toggleNavigationBar}
                        navigationBarState={navigationBarOpen}
                        showGlobalAddButton={showGlobalAddNewButton}
                        addSomethingNewHandler={handleClickAddSomethingNew}
                        authenticatedUser={{ ...authenticatedUser }}
                        setLoggingOutState={setIsLoggingOut}
                    />

                    <div className="push-container">
                        <div className="content">
                            <Outlet
                                context={{
                                    authenticatedUser: { ...authenticatedUser },
                                    toggleCartHandler: toggleCart,
                                    triggerAddNewAction: addSomethingNew,
                                    showAddNewButton: setShowAddNewButton,
                                }}
                            />
                        </div>
                        {/* <Footer /> */}
                    </div>
                </div>
            </div>
        );
    }

    return <Redirect redirectURL="/" />;
};

type ContextType = {
    authenticatedUser: AuthenticationType;
    showAddNewButton: React.Dispatch<React.SetStateAction<boolean>>;
    triggerAddNewAction: boolean;
    toggleCartHandler: () => void;
};

export function useAuthenticatedUserContext() {
    return useOutletContext<ContextType>();
}

export default AuthenticatedPageLayout;
