// import { queryClient } from '../../config/constants/app';
import { useAppSelector } from '../redux';
import useValidateSession from '../useValidateSession';
import { getAuthorizedHttpRequestConfig, useHttpMutation } from '../../utilities/http';
import { useToastNotification } from '../notification';

const useSiteInspectionActions = () => {
    const validateSession = useValidateSession();
    const authenticatedUser = useAppSelector((state) => state.authenticatedUser);

    const customMutateFn = useHttpMutation(['siteInspectionActions']);
    const toastNotification = useToastNotification();
    const handleActionError = (error: Record<string, any>) => {
        toastNotification(error.message, 'error');
    };

    const downloadSiteInspectionReport = (inspectionId: string, actionButton?: HTMLButtonElement) =>
        new Promise<Record<string, any>>((resolve) => {
            validateSession(authenticatedUser).then((authorizationToken) => {
                const httpRequestConfig = {
                    ...getAuthorizedHttpRequestConfig(authorizationToken),
                    url: '/site/inspection/download',
                    params: { inspectionId },
                };

                customMutateFn({
                    httpRequestConfig,
                    actionButton,
                    responseHandler: (response) => {
                        resolve(response);
                    },
                    errorHandler: handleActionError,
                });
            });
        });

    const downloadSiteInspectionSchedule = (params: Record<string, any>, actionButton?: HTMLButtonElement) =>
        new Promise<Record<string, any>>((resolve) => {
            validateSession(authenticatedUser).then((authorizationToken) => {
                const httpRequestConfig = {
                    ...getAuthorizedHttpRequestConfig(authorizationToken),
                    url: '/site/inspection/schedule/download',
                    params: { ...params },
                };

                customMutateFn({
                    httpRequestConfig,
                    actionButton,
                    responseHandler: (response) => {
                        resolve(response);
                    },
                    errorHandler: handleActionError,
                });
            });
        });

    return { downloadSiteInspectionReport, downloadSiteInspectionSchedule };
};

export default useSiteInspectionActions;
