import { useCallback } from 'react';

import { useAppSelector } from '../redux';
// eslint-disable-next-line import/no-cycle
import useValidateSession from '../useValidateSession';
import { queryClient, SYNC_TIMEOUT } from '../../config/constants/app';
import { getAuthorizedHttpRequestConfig, sendHttpRequest } from '../../utilities/http';

const useUserNotifications = () => {
    const validateSession = useValidateSession();
    const authenticatedUser = useAppSelector((state) => state.authenticatedUser);

    const fetchingNotifications = queryClient.isFetching({ queryKey: ['notifications'] }) > 0;

    const fetchNotifications = useCallback((filterParams?: Record<string, any>) => {
        const getNotifications = () =>
            validateSession(authenticatedUser).then((authorizationToken) => {
                const httpRequestConfig = {
                    ...getAuthorizedHttpRequestConfig(authorizationToken),
                    url: '/notification',
                    params: { ...filterParams },
                };

                return sendHttpRequest(httpRequestConfig);
            });

        return queryClient.fetchQuery({
            queryKey: ['notifications', { params: filterParams }],
            queryFn: getNotifications,
            staleTime: SYNC_TIMEOUT
        });
    }, []);

    return { fetchNotifications, fetchingNotifications };
};

export default useUserNotifications;
