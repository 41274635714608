import React from 'react';
import Button from '../../../components/Button';

type ModalFooterType = {
    enableSubmitButton?: boolean;
    submitButtonContent?: React.ReactNode;
    modalFormExitHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const ModalFormFooter: React.FC<ModalFooterType> = ({
    enableSubmitButton,
    modalFormExitHandler,
    submitButtonContent,
}) => (
    <div className="form-footer">
        <div className="row">
            <div className="col-4">
                <Button className="btn-default btn-block bg-white" onClick={modalFormExitHandler}>
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        <i className="custom-icon icon mr-auto" aria-label="Close" />
                        <span className="mr-auto">Cancel</span>
                    </div>
                </Button>
            </div>
            <div className="col-7 offset-1">
                <Button
                    type="submit"
                    className={`${enableSubmitButton ? 'btn-primary' : 'btn-default bg-white'} btn-block`}
                    disabled={!enableSubmitButton}
                >
                    {submitButtonContent}
                </Button>
            </div>
        </div>
    </div>
);

ModalFormFooter.defaultProps = {
    enableSubmitButton: true,
    submitButtonContent: (
        <div className="d-flex flex-row align-items-center justify-content-center">
            <i className="custom-icon icon mr-auto" style={{ backgroundColor: 'white' }} aria-label="Save" />
            <span className="mr-auto">Save</span>
        </div>
    ),
};

export default ModalFormFooter;
