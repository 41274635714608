import React from 'react';

interface LabeledCardType extends React.HTMLProps<HTMLDivElement> {
    label: string;
    backgroundColor?: string;
    children: React.ReactNode;
}

const LabeledCard = React.forwardRef<HTMLDivElement, LabeledCardType>(
    ({ label, backgroundColor, className, children, style }, ref) => (
        <div className={`labeled-card ${className || ''}`} style={{ ...style, backgroundColor }} ref={ref}>
            <div className="card-label" style={{ backgroundColor }}>
                {label}
            </div>
            {children}
        </div>
    ),
);

LabeledCard.defaultProps = {
    backgroundColor: '#ffffff',
};

export default LabeledCard;
