import React from 'react';

interface CardType extends React.HTMLProps<HTMLDivElement> {
    backgroundColor?: string;
    children: React.ReactNode;
}

const Card = React.forwardRef<HTMLDivElement, CardType>(({ backgroundColor, className, children }, ref) => (
    <div className={`card ${className}`} style={{ backgroundColor }} ref={ref}>
        {children}
    </div>
));

Card.defaultProps = {
    backgroundColor: '#ffffff',
};

export default Card;
