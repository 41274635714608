import React, { useRef } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';

import SidebarWrapper from '../../../SidebarWrapper';
import { AuthenticationType } from '../../../../@types/authentication';

type SidebarType = {
    authenticatedUser: AuthenticationType;
    navigationBarOpen: boolean;
    toggleNavigation: () => void;
};

const AuthenticatedPageLayoutSidebar: React.FC<SidebarType> = ({
    authenticatedUser,
    navigationBarOpen,
    toggleNavigation,
}) => {
    const navigate = useNavigate();
    const navMenu = useRef<HTMLElement | null>(null);

    const handleSidebarNavigation = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        if (navigationBarOpen) {
            toggleNavigation();
        }

        (navMenu?.current as HTMLElement)?.childNodes.forEach((link) => {
            const navLink = link as HTMLAnchorElement;
            if (navLink) {
                navLink.classList.remove('active');
            }
        });

        const baseUrl = window.location.origin;
        const urlPathName = event.currentTarget.href.replace(`${baseUrl}`, '');
        navigate(urlPathName);
    };

    const { privileges: userPrivileges } = authenticatedUser;

    return (
        <SidebarWrapper>
            <div className="logo">
                <Link to="/home" onClick={handleSidebarNavigation}>
                    <i className="main-logo" style={{ width: 40, height: 40 }} />
                </Link>
            </div>
            <nav ref={navMenu}>
                <NavLink to="/home" onClick={handleSidebarNavigation} className="tooltip-right" data-tooltip="Home">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-label="Home"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-bar-chart-2"
                    >
                        <line x1="18" y1="20" x2="18" y2="10" />
                        <line x1="12" y1="20" x2="12" y2="4" />
                        <line x1="6" y1="20" x2="6" y2="14" />
                    </svg>
                    <span>Home</span>
                </NavLink>
                {userPrivileges.orders ? (
                    <NavLink
                        to="/home/orders"
                        onClick={handleSidebarNavigation}
                        className="tooltip-right"
                        data-tooltip="Orders"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-shopping-cart"
                            aria-label="Orders"
                        >
                            <circle cx="9" cy="21" r="1" />
                            <circle cx="20" cy="21" r="1" />
                            <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" />
                        </svg>
                        <span>Orders</span>
                    </NavLink>
                ) : null}

                {userPrivileges.supplies || userPrivileges.dispatch ? (
                    <NavLink
                        to="/home/supplies"
                        onClick={handleSidebarNavigation}
                        className="tooltip-right"
                        data-tooltip="Supplies"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-archive"
                            aria-label="SupplyItemList"
                        >
                            <polyline points="21 8 21 21 3 21 3 8" />
                            <rect x="1" y="3" width="22" height="5" />
                            <line x1="10" y1="12" x2="14" y2="12" />
                        </svg>
                        <span>Supplies</span>
                    </NavLink>
                ) : null}

                {userPrivileges.sites ? (
                    <NavLink
                        to="/home/sites"
                        onClick={handleSidebarNavigation}
                        className="tooltip-right"
                        data-tooltip="Sites"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            aria-label="Sites"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-map-pin"
                        >
                            <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                            <circle cx="12" cy="10" r="3" />
                        </svg>
                        <span>Sites</span>
                    </NavLink>
                ) : null}

                {/*
            <NavLink to="/reports" onClick={handleSidebarNavigation} className="tooltip-right" data-tooltip="Reports">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-file-text"
                >
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                    <polyline points="14 2 14 8 20 8" />
                    <line x1="16" y1="13" x2="8" y2="13" />
                    <line x1="16" y1="17" x2="8" y2="17" />
                    <polyline points="10 9 9 9 8 9" />
                </svg>
                <span>Reports</span>
            </NavLink>
            */}

                {userPrivileges.team ? (
                    <>
                        <hr className="nav-divider" aria-hidden />

                        <NavLink
                            to="/settings"
                            onClick={handleSidebarNavigation}
                            className="tooltip-right"
                            data-tooltip="Settings"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                aria-label="Settings"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-settings"
                            >
                                <circle cx="12" cy="12" r="3" />
                                <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
                            </svg>
                            <span>Settings</span>
                        </NavLink>
                    </>
                ) : null}
            </nav>
        </SidebarWrapper>
    );
};

export default AuthenticatedPageLayoutSidebar;
