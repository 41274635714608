import React from 'react';

type OptionInputType = React.HTMLProps<HTMLInputElement>;

const OptionInput = React.forwardRef<HTMLInputElement, OptionInputType>((props, ref) => {
    const { id, type, children, name, onChange, disabled, defaultValue, defaultChecked } = props;

    return (
        <div className="form-check check-lg checkbox-circle complete">
            <input
                type={type || 'checkbox'}
                ref={ref}
                onChange={onChange}
                name={name}
                id={id}
                value={defaultValue}
                defaultChecked={defaultChecked}
                disabled={disabled}
            />
            <label htmlFor={id}>{children}</label>
        </div>
    );
});

export default OptionInput;
