import { useCallback } from 'react';

import useAuthorisedTeamMembers from "./useAuthorisedTeamMembers";
import { useAppDispatch } from "../redux";
import { setAuthorisedTeamMemberOptions } from "../../redux/reducers/team";
import type { AuthorisedMembers } from "../../@types/authentication";

const useAuthorisedTeamMemberOptions = () => {
    const reduxDispatch = useAppDispatch();
    const {
        fetchAuthorisedTeamMembers,
        fetchingAuthorisedTeamMembers: fetchingAuthorisedTeamMemberOptions
    } = useAuthorisedTeamMembers();

    const refreshAuthorisedTeamMemberOptions = useCallback(() => {
        fetchAuthorisedTeamMembers()
            .then((response) => {
                const updatedAuthorisedTeamMembers = response?.records as AuthorisedMembers[] || [];
                reduxDispatch(setAuthorisedTeamMemberOptions(updatedAuthorisedTeamMembers));
            })
    }, []);

    return { refreshAuthorisedTeamMemberOptions, fetchingAuthorisedTeamMemberOptions };
};

export default useAuthorisedTeamMemberOptions;
