import React from 'react';
import './OptionCardStyles.scss';

interface OptionCardType extends React.HTMLProps<HTMLInputElement> {
    description?: string;
}

const OptionCard: React.FC<OptionCardType> = (props) => {
    const { id, type, title, description } = props;
    return (
        <label className="option-card" htmlFor={id}>
            <input {...props} type={type ?? 'checkbox'} />
            <div className="option-card-body">
                <div className="option-card-body-content">
                    <h2>
                        {title && description && (
                            <>
                                {title}
                                <small>{description}</small>
                            </>
                        )}
                        {title && !description && <small>{title}</small>}
                    </h2>
                </div>
            </div>
        </label>
    );
};

OptionCard.defaultProps = {
    description: undefined,
};

export default OptionCard;
