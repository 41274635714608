import useValidateSession from '../useValidateSession';
import { useToastNotification } from '../notification';
import useAuthorisedTeamMemberOptions from "./useAuthorisedTeamMemberOptions";
import { useAppDispatch, useAppSelector } from '../redux';
import { deleteAuthorisedTeamMemberOption } from "../../redux/reducers/team";
import { queryClient } from '../../config/constants/app';
import { getAuthorizedHttpRequestConfig, useHttpMutation } from '../../utilities/http';
import type { TeamMemberType } from '../../@types/team/teamMember';

const useTeamMemberActions = () => {
    const validateSession = useValidateSession();
    const reduxDispatch = useAppDispatch();
    const authenticatedUser = useAppSelector((state) => state.authenticatedUser);
    const { refreshAuthorisedTeamMemberOptions } = useAuthorisedTeamMemberOptions();

    const toastNotification = useToastNotification();
    const customMutationFn = useHttpMutation(['teamMembers']);
    const teamMemberActionInProgress = queryClient.isMutating({ mutationKey: ['teamMembers'] }) > 0;

    const addUpdateTeamMember = (teamMemberData: TeamMemberType, actionButton?: HTMLButtonElement) =>
        new Promise<Record<string, any>>((resolve, reject) => {
            validateSession(authenticatedUser)
                .then((authorizationToken) => {
                    const { memberId } = teamMemberData;
                    const httpMethod = memberId.trim() === '' ? 'POST' : 'PATCH';

                    const updatedTeamMemberData = {
                        userId: memberId,
                        memberStatus: teamMemberData.memberStatus,
                        teamId: teamMemberData.teamId,
                        siteId: teamMemberData.siteId,
                        firstName: teamMemberData.firstName,
                        lastName: teamMemberData.lastName,
                        tel: teamMemberData.tel,
                        altTel: teamMemberData.altTel,
                        email: teamMemberData.email,
                        password1: teamMemberData.password1,
                        password2: teamMemberData.password2,
                    };

                    const httpRequestConfig = {
                        ...getAuthorizedHttpRequestConfig(authorizationToken, httpMethod),
                        url: '/team/member',
                        data: updatedTeamMemberData,
                    };

                    customMutationFn({
                        actionButton,
                        httpRequestConfig,
                        responseHandler: (response) => {
                            resolve(response);
                            queryClient.refetchQueries({ queryKey: ['teamMembers'] })
                                .then(() => {
                                    refreshAuthorisedTeamMemberOptions();
                                });
                        },
                        errorHandler: (error) => {
                            toastNotification(error.message, 'error');
                        },
                    });
                })
                .catch(() => {
                    reject(new Error('Invalid or expired session, please login.'));
                });
        });

    type ToggleMemberStatusType = { userId: string; memberStatus: boolean };

    const toggleTeamMemberStatus = (data: ToggleMemberStatusType, actionButton?: HTMLButtonElement) =>
        new Promise<Record<string, any>>((resolve, reject) => {
            validateSession(authenticatedUser)
                .then((authorizationToken) => {
                    const httpRequestConfig = {
                        ...getAuthorizedHttpRequestConfig(authorizationToken, 'PATCH'),
                        url: '/team/member/status',
                        data: { userId: data.userId, memberStatus: +data.memberStatus },
                    };

                    customMutationFn({
                        actionButton,
                        httpRequestConfig,
                        responseHandler: (response) => {
                            resolve(response);
                            queryClient.refetchQueries({ queryKey: ['teamMembers'] })
                                .then(() => {
                                    if (+data.memberStatus) {
                                        refreshAuthorisedTeamMemberOptions();
                                        return;
                                    }

                                    reduxDispatch(deleteAuthorisedTeamMemberOption(data.userId))
                                });
                        },
                        errorHandler: (error) => {
                            toastNotification(error.message, 'error');
                        },
                    });
                })
                .catch(() => {
                    reject(new Error('Invalid or expired session, please login.'));
                });
        });

    return { addUpdateTeamMember, toggleTeamMemberStatus, teamMemberActionInProgress };
};

export default useTeamMemberActions;
