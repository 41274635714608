import React from 'react';

const insertAfter = (referenceNode: HTMLDivElement, newNode: HTMLLabelElement) => {
    referenceNode?.parentNode?.insertBefore(newNode, referenceNode?.nextSibling);
};

export const removeInputError = (errorElemId: string) => {
    if (document.getElementById(errorElemId)) {
        document.getElementById(errorElemId)?.remove();
    }
};

const showInputError = (referenceNode: HTMLDivElement, errorElemId: string, errorMessage: string) => {
    removeInputError(errorElemId);
    const errorElement = document.createElement('label');
    errorElement.id = errorElemId;
    errorElement.classList.add('error');
    errorElement.innerHTML = errorMessage;
    insertAfter(referenceNode, errorElement);
};

export const showInputElementError = (validationErrors: { [key: string]: string }) => {
    Object.entries(validationErrors).forEach(([key, value]) => {
        const htmlElem = document.getElementsByName(key)[0] as HTMLInputElement;
        if (htmlElem && (htmlElem.type.toLowerCase() !== 'hidden' || !htmlElem.classList.contains('hidden'))) {
            const parentElem = htmlElem?.parentElement;
            if (
                parentElem &&
                parentElem.classList.contains('form-group-default') &&
                !parentElem.classList.contains('hidden')
            ) {
                htmlElem.classList.add('error');
                parentElem.classList.add('has-error');
                showInputError(parentElem as HTMLDivElement, `${key}Error`, value);
            }
        }
    });
};

const requiredInputElement = (inputElement: HTMLInputElement | HTMLTextAreaElement) => {
    if (inputElement.classList) {
        inputElement.classList.add('error');
    }
    if (inputElement.parentElement) {
        inputElement.parentElement.classList.add('has-error');
        showInputError(
            inputElement.parentElement as HTMLDivElement,
            `${inputElement?.name}Error`,
            `This field is required.`,
        );
    }

    return false;
};

export const validateFormElement = (formEvent: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (formEvent.target.tagName.toLowerCase() !== 'hidden') {
        const elementValue = formEvent.target.value.toString().trim();
        if (!(elementValue.split('').length > 0) && formEvent.target.required) {
            return requiredInputElement(formEvent.target);
        }
    }

    return true;
};

export const removeFormElementError = (formElement: HTMLInputElement | HTMLTextAreaElement) => {
    if (formElement.classList) {
        formElement.classList.remove('error');
    }
    if (formElement.offsetParent) {
        formElement.offsetParent.classList.remove('has-error');
    }
    removeInputError(`${formElement.name}Error`);
};
