import { useEffect, useState } from 'react';

import { useAppSelector } from '../redux';
import { cartDataTemplate } from '../../config/constants/dataTemplates/cartDataTemplate';

const useShoppingCart = () => {
    const [shoppingCart, setShoppingCart] = useState(cartDataTemplate);
    const reduxShoppingCart = useAppSelector((state) => state.cart);

    useEffect(() => {
        setShoppingCart(reduxShoppingCart);
    }, [reduxShoppingCart]);

    return { shoppingCart };
};

export default useShoppingCart;
