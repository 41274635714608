import { useCallback } from 'react';

import { queryClient, SYNC_TIMEOUT } from '../../config/constants/app';
import { useAppSelector } from '../redux';
import useValidateSession from '../useValidateSession';
import { getAuthorizedHttpRequestConfig, sendHttpRequest } from '../../utilities/http';

const useSites = () => {
    const validateSession = useValidateSession();
    const authenticatedUser = useAppSelector((state) => state.authenticatedUser);
    const fetchingSites = queryClient.isFetching({ queryKey: ['sites'] }) > 0;

    const fetchSites = useCallback((filterParams?: Record<string, any>): Promise<Record<string, any>> => {
        const getSites = () =>
            validateSession(authenticatedUser).then((authenticationToken) => {
                const httpRequestConfig = {
                    ...getAuthorizedHttpRequestConfig(authenticationToken),
                    url: '/site',
                    params: filterParams,
                };

                return sendHttpRequest(httpRequestConfig);
            });

        return queryClient.fetchQuery({
            queryKey: ['sites', { params: filterParams }],
            queryFn: getSites,
            staleTime: SYNC_TIMEOUT,
        });
    }, []);

    return { fetchSites, fetchingSites };
};

export default useSites;
