import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

type AuthenticatedChildPageLayoutType = {
    pageTitle: string;
    pageSubtitle: string;
};

const AuthenticatedChildHeader: React.FC<AuthenticatedChildPageLayoutType> = ({ pageTitle, pageSubtitle }) => {
    const navigate = useNavigate();
    const exitChildPageHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        navigate(-1);
    };

    return (
        <div className="child-page-header">
            <div className="header-inner">
                <div>
                    <i className="logo" style={{ width: 100 }} />
                </div>
                <div className="title-divider" />
                <div className="header-title">
                    <h4 className="no-margin p-t-10">{pageTitle}</h4>
                    <p className="no-margin bold">{pageSubtitle}</p>
                </div>
                <div>
                    <Link to="/" onClick={exitChildPageHandler}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#353f4d"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                        >
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default AuthenticatedChildHeader;
