import React from 'react';

interface CustomDropdownToggleType extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode;
}

export const CustomDropdownToggle = React.forwardRef<HTMLButtonElement, CustomDropdownToggleType>((props, ref) => {
    const { children, className, onClick } = props;
    return (
        <button
            className={className}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            aria-label="dropdown"
            ref={ref}
            onClick={onClick}
            type="button"
        >
            {children}
        </button>
    );
});
