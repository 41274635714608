import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuthorisedMembers } from '../../../@types/authentication';

const authorisedTeamMemberOptions = createSlice({
    name: 'Authorised Member Options',
    initialState: [] as AuthorisedMembers[],
    reducers: {
        setAuthorisedTeamMemberOptions: (state, action: PayloadAction<AuthorisedMembers[]>) =>
        [...action.payload],

        deleteAuthorisedTeamMemberOption: (state, action: PayloadAction<string>) =>
            [...state].filter((option) => option.memberId !== action.payload)
    },
});

export const {
    deleteAuthorisedTeamMemberOption,
    setAuthorisedTeamMemberOptions
} = authorisedTeamMemberOptions.actions;

export default authorisedTeamMemberOptions.reducer;
