import { useCallback } from 'react';

import useSites from './useSites';
import { useAppDispatch } from "../redux";
import { setSiteOptions } from "../../redux/reducers/sites";
import type { SiteListType } from "../../@types/sites/site";

const useSiteOptions = () => {
    const { fetchSites, fetchingSites: fetchingSiteOptions } = useSites();
    const reduxDispatch = useAppDispatch();

    const refreshSiteOptions = useCallback((orderFulfilmentSites = false) => {
        fetchSites().then((updatedSites) => {
            let updatedSiteRecords = updatedSites?.records as SiteListType[] || [];
            if (orderFulfilmentSites) {
                updatedSiteRecords = [...updatedSiteRecords].filter((site) => site.orderFulfilment)
            }
            reduxDispatch(setSiteOptions(updatedSiteRecords));
        });
    }, []);

    return { refreshSiteOptions, fetchingSiteOptions };
};

export default useSiteOptions;
