import { useCallback } from 'react';

import { useAppSelector } from '../redux';
import useValidateSession from '../useValidateSession';
import { queryClient, SYNC_TIMEOUT } from '../../config/constants/app';
import { getAuthorizedHttpRequestConfig, sendHttpRequest } from '../../utilities/http';

const useTeamList = () => {
    const validateSession = useValidateSession();
    const authenticatedUser = useAppSelector((state) => state.authenticatedUser);

    const fetchingTeams = queryClient.isFetching({ queryKey: ['teams'] }) > 0;
    const fetchTeams = useCallback((filterParams?: Record<string, any>) => {
        const getTeams = () =>
            validateSession(authenticatedUser).then((authorizationToken) => {
                const httpRequestConfig = {
                    ...getAuthorizedHttpRequestConfig(authorizationToken.trim()),
                    url: '/team',
                    params: filterParams,
                };

                return sendHttpRequest(httpRequestConfig);
            });

        return queryClient.fetchQuery({
            queryKey: ['teams', { params: filterParams }],
            queryFn: getTeams,
            staleTime: SYNC_TIMEOUT
        });
    }, []);

    return { fetchTeams, fetchingTeams };
};

export default useTeamList;
