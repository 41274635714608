import { CartItemType } from '../../@types/cart';

export const formatCartItemQuantity = (item: CartItemType) => {
    const { itemQuantity, itemMinimumSaleQuantity, itemSIUnit } = item;

    const totalItemQuantity = itemQuantity;
    let updatedCartItemQuantity = totalItemQuantity.toString();

    if (itemMinimumSaleQuantity > 1) {
        updatedCartItemQuantity = `${totalItemQuantity / itemMinimumSaleQuantity} x ${itemMinimumSaleQuantity}`;
    }

    updatedCartItemQuantity += itemSIUnit ? ` ${itemSIUnit}(s)` : '';
    return updatedCartItemQuantity;
};
