import React from 'react';
import type { SingleValue, MultiValue } from 'react-select';

import SelectSiteOptions from '../../SelectOptions/SelectSiteOptions';
import SelectDriverOptions from '../../SelectOptions/SelectDriverOptions';
import ModalContainer, { ModalHeader } from '../../../containers/ModalContainer';
import Button from '../../Button';

import useScreenWidth from '../../../hooks/useScreenWidth';
import { CartListItem, CartModalFooter, EmptyCart, MobileCartListItem } from './components';
import type { CartType } from '../../../@types/cart';
import type { AuthenticationType } from '../../../@types/authentication';
import type { SelectSingleOptionType } from '../../FormElements';

type QuickOrderCartType = {
    cart: CartType;
    authenticatedUser: AuthenticationType;
    openQuickOrderCart: boolean;
    closeQuickOrderCartHandler: () => void;
    selectOptionUpdateHandler: (optionName: string, optionValue: string) => void;
    updateItemQuantityHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
    removeItemFromCartHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
    clearCartHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
    checkoutHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const QuickOrderCart: React.FC<QuickOrderCartType> = ({
    cart,
    authenticatedUser,
    openQuickOrderCart,
    closeQuickOrderCartHandler,
    selectOptionUpdateHandler,
    updateItemQuantityHandler,
    removeItemFromCartHandler,
    clearCartHandler,
    checkoutHandler,
}) => {
    const { privileges: userPrivileges } = authenticatedUser;
    const { siteId, driverId, cartItems } = cart;

    const handleSelectOptionUpdate = (
        optionName: string,
        option: SingleValue<SelectSingleOptionType> | MultiValue<SelectSingleOptionType>,
    ) => {
        selectOptionUpdateHandler(optionName, (option as SelectSingleOptionType)?.value || '');
    };

    const cartModalExitHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        closeQuickOrderCartHandler();
    };

    const screenWidth = useScreenWidth(window.innerHeight);

    return (
        <ModalContainer show={openQuickOrderCart} closeModal={closeQuickOrderCartHandler}>
            <ModalHeader modalExitHandler={cartModalExitHandler}>
                <h5 className="mr-auto">Cart</h5>
                {cartItems.length > 0 && (
                    <span className="text-right">
                        <Button onClick={clearCartHandler} className="btn-danger">
                            <i className="custom-icon icon" style={{ backgroundColor: '#ffffff' }} aria-label="Trash" />
                        </Button>
                    </span>
                )}
            </ModalHeader>
            <div className="modal-body">
                {cartItems.length > 0 ? (
                    <>
                        <SelectSiteOptions
                            placeholder="Select site"
                            selectedOption={siteId}
                            onChange={(option) => {
                                handleSelectOptionUpdate('siteId', option as SelectSingleOptionType);
                            }}
                        />

                        {userPrivileges.supplies || userPrivileges.dispatch ? (
                            <SelectDriverOptions
                                placeholder="Select driver"
                                selectedOption={driverId}
                                onChange={(option) => {
                                    handleSelectOptionUpdate('driverId', option as SelectSingleOptionType);
                                }}
                            />
                        ) : null}

                        {screenWidth >= 768 ? (
                            <>
                                {cartItems.map((cartItem) => (
                                    <CartListItem
                                        key={cartItem.itemId}
                                        cartItem={cartItem}
                                        updateItemQuantityHandler={updateItemQuantityHandler}
                                        removeItemFromCartHandler={removeItemFromCartHandler}
                                    />
                                ))}
                            </>
                        ) : (
                            <div className="mt-2">
                                {cartItems.map((cartItem) => (
                                    <MobileCartListItem
                                        key={cartItem.itemId}
                                        cartItem={cartItem}
                                        updateItemQuantityHandler={updateItemQuantityHandler}
                                        removeItemFromCartHandler={removeItemFromCartHandler}
                                    />
                                ))}
                            </div>
                        )}

                        <CartModalFooter
                            userPrivileges={userPrivileges}
                            cartModalExitHandler={cartModalExitHandler}
                            checkoutHandler={checkoutHandler}
                        />
                    </>
                ) : (
                    <EmptyCart cartModalExitHandler={cartModalExitHandler} />
                )}
            </div>
        </ModalContainer>
    );
};

export default QuickOrderCart;
