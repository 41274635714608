import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { AuthenticationPage, AuthenticatedChildPage, AuthenticatedPage, ErrorPage } from '../containers/Layout';
import { FullPageProgressIndicator } from '../components/ProgressIndicator';

import useAuthenticatedUser from '../hooks/useAuthenticatedUser';

const Login = lazy(() => import('../pages/LoginPage'));
const PageNotFound = lazy(() => import('../pages/PageNotFound'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Supplies = lazy(() => import('../pages/Supplies'));
const SupplyItemForm = lazy(() => import('../pages/Supplies/SupplyItemForm'));
const Sites = lazy(() => import('../pages/Sites'));
const SiteForm = lazy(() => import('../pages/Sites/SiteForm'));
const Orders = lazy(() => import('../pages/Orders'));
const ProcessOrder = lazy(() => import('../pages/Dispatch'));
const FieldTeamHomePage = lazy(() => import('../pages/FieldTeamHome'));
const Team = lazy(() => import('../pages/Team'));
const TeamForm = lazy(() => import('../pages/Team/TeamForm'));
const MemberProfile = lazy(() => import('../pages/MemberProfile'));
const SiteInspectionPage = lazy(() => import('../pages/SiteInspection'));
const SiteDetailPage = lazy(() => import('../pages/Sites/SiteDetail'));

const PrimaryRouter = () => {
    const { authenticatedUser } = useAuthenticatedUser();
    const { privileges: userPrivileges } = authenticatedUser;

    return (
        <BrowserRouter>
            <Suspense fallback={<FullPageProgressIndicator style={{ zIndex: 4999 }} />}>
                <Routes>
                    {userPrivileges.delivery || userPrivileges.siteInspection || userPrivileges.shop ? (
                        <>
                            <Route path="/home/*" element={<AuthenticatedPage />}>
                                <Route index element={<FieldTeamHomePage />} />
                            </Route>
                            <Route path="/manage/*" element={<AuthenticatedChildPage />}>
                                <Route path="sites/inspection/:siteId" element={<SiteInspectionPage />} />
                            </Route>
                        </>
                    ) : (
                        <>
                            <Route path="/home/*" element={<AuthenticatedPage />}>
                                <Route index element={<Dashboard />} />
                                <Route path="supplies" element={<Supplies />} />
                                <Route path="sites" element={<Sites />} />
                                <Route path="orders" element={<Orders />} />
                            </Route>

                            <Route path="/settings/*" element={<AuthenticatedPage />}>
                                <Route index element={<Team />} />
                                <Route path="team" element={<Team />} />
                                <Route path="profile" element={<MemberProfile />} />
                            </Route>

                            <Route path="/site/detail/:siteId" element={<AuthenticatedChildPage />}>
                                <Route index element={<SiteDetailPage />} />
                            </Route>

                            <Route path="/manage/*" element={<AuthenticatedChildPage />}>
                                <Route index element={<SupplyItemForm />} />
                                <Route path="supplies" element={<SupplyItemForm />} />
                                <Route path="supplies/:itemId" element={<SupplyItemForm />} />
                                <Route path="sites" element={<SiteForm />} />
                                <Route path="sites/:siteId" element={<SiteForm />} />
                                <Route path="sites/inspection/:siteId" element={<SiteInspectionPage />} />
                                <Route path="orders" element={<ProcessOrder />} />
                                <Route path="orders/:orderId" element={<ProcessOrder />} />
                                <Route path="team" element={<TeamForm />} />
                                <Route path="team/:teamId" element={<TeamForm />} />
                            </Route>
                        </>
                    )}
                    <Route path="/profile/*" element={<AuthenticatedChildPage />}>
                        <Route index element={<MemberProfile />} />
                    </Route>
                    <Route path="/page-not-found/*" element={<ErrorPage />}>
                        <Route index element={<PageNotFound />} />
                    </Route>
                    <Route path="/*" element={<AuthenticationPage />}>
                        <Route index element={<Login />} />
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
};

export default PrimaryRouter;
