import { useCallback } from 'react';

// eslint-disable-next-line import/no-cycle
import useValidateSession from '../useValidateSession';
import useShowBrowserNotification from './useShowBrowserNotification';
import { queryClient } from '../../config/constants/app';
import useToastNotification from './useToastNotification';
import { getAuthorizedHttpRequestConfig, useHttpMutation } from '../../utilities/http';
import { useAppSelector } from '../redux';
import type { UserNotificationType } from '../../@types/userNotification';

const useUserNotificationActions = () => {
    const validateSession = useValidateSession();
    const showBrowserNotification = useShowBrowserNotification();
    const authenticatedUser = useAppSelector((state) => state.authenticatedUser);

    const processingNotifications = queryClient.isMutating({ mutationKey: ['notifications'] });
    const customMutationFn = useHttpMutation(['notifications']);
    const toastNotification = useToastNotification();

    const addNotification = useCallback(
        (notification: UserNotificationType, actionButton?: HTMLButtonElement) =>
            new Promise<Record<string, any>>((resolve, reject) => {
                validateSession(authenticatedUser)
                    .then((authenticationToken) => {
                        const httpRequestConfig = {
                            ...getAuthorizedHttpRequestConfig(authenticationToken, 'POST'),
                            url: '/notification',
                            data: { ...notification },
                        };

                        customMutationFn({
                            actionButton,
                            httpRequestConfig,
                            responseHandler: (response) => {
                                resolve(response);
                            },
                            errorHandler: (error) => {
                                toastNotification(error.message, 'error');
                            },
                        });
                    })
                    .catch(() => {
                        reject(new Error('Invalid or expired session, please login.'));
                    });
            }),
        [],
    );

    type UpdateNotificationStatusType = {
        notifications: string[];
        notificationStatus: number;
    };

    const updateNotificationStatus = useCallback(
        (notification: UpdateNotificationStatusType, actionButton?: HTMLButtonElement) =>
            new Promise<Record<string, any>>((resolve, reject) => {
                validateSession(authenticatedUser)
                    .then((authenticationToken) => {
                        const httpRequestConfig = {
                            ...getAuthorizedHttpRequestConfig(authenticationToken, 'PATCH'),
                            url: '/notification/status',
                            data: { ...notification },
                        };

                        customMutationFn({
                            actionButton,
                            httpRequestConfig,
                            responseHandler: async (response) => {
                                resolve(response);
                                await queryClient.refetchQueries({ queryKey: ['notifications'], type: 'active' });
                            },
                            errorHandler: (error) => {
                                toastNotification(error.message, 'error');
                            },
                        });
                    })
                    .catch(() => {
                        reject(new Error('Invalid or expired session, please login.'));
                    });
            }),
        [],
    );

    const deleteNotification = useCallback(
        (notifications: string[], actionButton?: HTMLButtonElement) =>
            new Promise<Record<string, any>>((resolve, reject) => {
                validateSession(authenticatedUser)
                    .then((authenticationToken) => {
                        const httpRequestConfig = {
                            ...getAuthorizedHttpRequestConfig(authenticationToken, 'DELETE'),
                            url: '/notification',
                            data: { notifications },
                        };

                        customMutationFn({
                            actionButton,
                            httpRequestConfig,
                            responseHandler: async (response) => {
                                resolve(response);
                                await queryClient.refetchQueries({ queryKey: ['notifications'], type: 'active' });
                            },
                            errorHandler: (error) => {
                                toastNotification(error.message, 'error');
                            },
                        });
                    })
                    .catch(() => {
                        reject(new Error('Invalid or expired session, please login.'));
                    });
            }),
        [],
    );

    return {
        showBrowserNotification,
        addNotification,
        deleteNotification,
        updateNotificationStatus,
        processingNotifications,
    };
};

export default useUserNotificationActions;
