import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import { QueryClientProvider } from '@tanstack/react-query';

import { useAppDispatch } from './hooks/redux';
import { initialiseCart } from './redux/reducers/cart';

import { APP_STORAGE_KEY, queryClient } from './config/constants/app';

import PrimaryRouter from './routes/PrimaryRouter';

let loadAppReduxStoreItemsOnce = true;

const App = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!loadAppReduxStoreItemsOnce) return;
        loadAppReduxStoreItemsOnce = false;
        if (dispatch && !localStorage.getItem(APP_STORAGE_KEY)) {
            dispatch(initialiseCart);
        }
    }, [dispatch]);

    return (
        <QueryClientProvider client={queryClient}>
            <Toaster position="top-center" reverseOrder={false} />
            <PrimaryRouter />
        </QueryClientProvider>
    );
};

export default connect()(App);
