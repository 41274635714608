import axios, { AxiosRequestConfig } from 'axios';
import { useMutation } from '@tanstack/react-query';

import { showInputElementError } from '../inputValidation/inputValidationHandlers';
import { BASE_API_URL } from '../../config/constants/app';

export const toggleActionButtonLoadingState = (actionButton?: HTMLButtonElement) => {
    if (actionButton) {
        actionButton.classList.toggle('loading');
    }
};

export const sendHttpRequest = (requestConfig: AxiosRequestConfig, actionButton?: HTMLButtonElement) =>
    new Promise<Record<string, any>>((resolve, reject) => {
        axios({ ...requestConfig, baseURL: BASE_API_URL })
            .then((response) => {
                toggleActionButtonLoadingState(actionButton);
                resolve(response.data);
            })
            .catch((error) => {
                toggleActionButtonLoadingState(actionButton);
                if (error.response && error.response.data) {
                    const { error: httpErrorMessage, message, fields: validationErrors } = error.response.data;
                    if (validationErrors && Object.entries(validationErrors).length > 0) {
                        showInputElementError(validationErrors);
                    }
                    let errMessage = (httpErrorMessage as string) || message;
                    if (errMessage.search('Data validation error') !== -1) {
                        errMessage = '';
                    }
                    reject(new Error(errMessage));
                }

                reject(new Error(error.message));
            });
    });

interface HandleHttpRequestType {
    httpRequestConfig: AxiosRequestConfig;
    actionButton?: HTMLButtonElement;
}

const handleHttpRequest = (params: HandleHttpRequestType) => {
    const { httpRequestConfig, actionButton } = params;
    toggleActionButtonLoadingState(actionButton);
    return sendHttpRequest(httpRequestConfig, actionButton);
};

type HttpResponseHandlerType = (response: Record<string, any>) => void;
interface HttpUseMutationType extends HandleHttpRequestType {
    responseHandler: HttpResponseHandlerType;
    errorHandler?: HttpResponseHandlerType;
}
export const useHttpMutation = (queryId: any) => {
    const { mutate } = useMutation({
        mutationKey: queryId,
        mutationFn: handleHttpRequest,
        onSuccess: (data) => data,
        onError: (data) => data,
    });

    return (mutationConfig: HttpUseMutationType) => {
        const { httpRequestConfig, responseHandler, errorHandler, actionButton } = mutationConfig;
        mutate(
            { httpRequestConfig, actionButton },
            {
                onSuccess: (data) => {
                    responseHandler(data as Record<string, any>);
                },
                onError: (data) => {
                    if (errorHandler) {
                        errorHandler(data as Record<string, any>);
                    }
                },
            },
        );
    };
};
