import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SelectSingleOptionType } from '../../../components/FormElements';
import { SiteGroupListType } from '../../../@types/sites/siteGroup';

const siteGroupOptions = createSlice({
    name: 'Site Group Options',
    initialState: [] as SelectSingleOptionType[],
    reducers: {
        setSiteGroupOptions: (state, action: PayloadAction<SiteGroupListType[]>) =>
            [...action.payload].map((siteGroup) => ({
                value: siteGroup.groupId,
                label: siteGroup.groupName,
            })),

        deleteSiteGroupOption: (state, action: PayloadAction<string>) =>
            [...state].filter((option) => option.value !== action.payload)
    },
});

export const { deleteSiteGroupOption, setSiteGroupOptions } = siteGroupOptions.actions;
export default siteGroupOptions.reducer;
