import { Method } from 'axios';

export const getHttpRequestConfig = (requestMethod: Method = 'GET') => ({
    method: requestMethod,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const getAuthorizedHttpRequestConfig = (token: string, requestMethod: Method = 'GET') => {
    const httpRequestConfig = getHttpRequestConfig(requestMethod);
    return {
        ...httpRequestConfig,
        headers: {
            ...httpRequestConfig.headers,
            Authorization: `Bearer ${token.trim()}`,
        },
    };
};
