import React, { useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import AuthenticatedChildPageLayoutHeader from './components';
import Redirect from '../../../routes/Redirect';
import useAuthenticatedUser from '../../../hooks/useAuthenticatedUser';
import { FullPageProgressIndicator } from '../../../components/ProgressIndicator';
import { AuthenticationType } from '../../../@types/authentication';

type AuthenticatedChildPageLayoutType = {
    pageTitle: string;
    pageSubtitle: string;
};

const AuthenticatedChildPageLayout: React.FC = () => {
    const { authenticatedUser, validatingUser } = useAuthenticatedUser();
    const [pageTitles, setPageTitles] = useState<AuthenticatedChildPageLayoutType>({
        pageTitle: '',
        pageSubtitle: '',
    });

    if (validatingUser) {
        return <FullPageProgressIndicator />;
    }

    if (authenticatedUser) {
        return (
            <div className="page-container">
                <div className="page-content-container child-page">
                    <AuthenticatedChildPageLayoutHeader
                        pageTitle={pageTitles.pageTitle}
                        pageSubtitle={pageTitles.pageSubtitle}
                    />
                    <div className="push-container">
                        <div className="content" style={{ marginTop: 10 }}>
                            <Outlet
                                context={{
                                    authenticatedUser,
                                    setPageTitles,
                                }}
                            />
                        </div>

                        {/* <Footer /> */}
                    </div>
                </div>
            </div>
        );
    }

    return <Redirect />;
};

type ContextType = {
    authenticatedUser: AuthenticationType;
    setPageTitles: React.Dispatch<React.SetStateAction<AuthenticatedChildPageLayoutType>>;
};

export function useAuthenticatedUserContext() {
    return useOutletContext<ContextType>();
}

export default AuthenticatedChildPageLayout;
