import type { Method } from 'axios';

import { queryClient } from '../../config/constants/app';
import { useAppDispatch, useAppSelector } from '../redux';
import { deleteSiteGroupOption } from "../../redux/reducers/sites";
import useSiteGroupOptions from "./useSiteGroupOptions";
import useValidateSession from '../useValidateSession';
import { getAuthorizedHttpRequestConfig, useHttpMutation } from '../../utilities/http';
import { useToastNotification } from '../notification';

import type { SiteGroupType } from '../../@types/sites/siteGroup';

const useSiteGroupActions = () => {
    const validateSession = useValidateSession();
    const reduxDispatch = useAppDispatch();
    const { refreshSiteGroupOptions } = useSiteGroupOptions();
    const authenticatedUser = useAppSelector((state) => state.authenticatedUser);

    const customMutateFn = useHttpMutation(['siteGroupActions']);
    const toastNotification = useToastNotification();
    const handleError = (error: Record<string, any>) => {
        toastNotification(error.message, 'error');
    };

    const addUpdateSiteGroup = (siteGroup: SiteGroupType, actionButton?: HTMLButtonElement) =>
        new Promise<Record<string, any>>((resolve, reject) => {
            validateSession(authenticatedUser)
                .then((authorizationToken) => {
                    const { groupId } = siteGroup;
                    const httpMethod = groupId.trim() === '' ? 'POST' : 'PATCH';
                    const httpRequestConfig = {
                        ...getAuthorizedHttpRequestConfig(authorizationToken, httpMethod as Method),
                        url: '/site/group',
                        data: siteGroup,
                    };

                    customMutateFn({
                        httpRequestConfig,
                        actionButton,
                        responseHandler: (response) => {
                            resolve(response);
                            queryClient.invalidateQueries({ queryKey: ['siteGroups'], type: 'all' }).then(() => {
                                refreshSiteGroupOptions();
                            });
                        },
                        errorHandler: handleError,
                    });
                })
                .catch(() => {
                    reject(new Error('Invalid or expired session, please login.'));
                });
        });

    const deleteSiteGroup = (siteGroupId: string, actionButton?: HTMLButtonElement) =>
        new Promise<Record<string, any>>((resolve, reject) => {
            validateSession(authenticatedUser)
                .then((authorizationToken) => {
                    const httpRequestConfig = {
                        ...getAuthorizedHttpRequestConfig(authorizationToken),
                        url: '/site/group',
                        data: { groupId: siteGroupId },
                        method: 'DELETE' as Method,
                    };

                    customMutateFn({
                        httpRequestConfig,
                        actionButton,
                        responseHandler: (response) => {
                            resolve(response);
                            queryClient.invalidateQueries({ queryKey: ['siteGroups'], type: 'all' }).then(() => {
                                reduxDispatch(deleteSiteGroupOption(siteGroupId));
                            });
                        },
                        errorHandler: handleError,
                    });
                })
                .catch(() => {
                    reject(new Error('Invalid or expired session, please login.'));
                });
        });

    return { addUpdateSiteGroup, deleteSiteGroup };
};

export default useSiteGroupActions;
