import React from 'react';
import { format, parseISO, isToday, isYesterday, isSameHour, differenceInMinutes } from 'date-fns';

import DataListItem from '../../DataListItem';
import Button from '../../Button';
import { UserNotificationType } from '../../../@types/userNotification';

type NotificationItemType = {
    notification: UserNotificationType;
    notificationType: string;
    updateNotificationStatusHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
    deleteNotificationHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const NotificationItem: React.FC<NotificationItemType> = ({
    notification,
    notificationType,
    updateNotificationStatusHandler,
    deleteNotificationHandler,
}) => {
    const getDate = (strDate: string) => {
        const parsedDate = parseISO(strDate);
        const minutePassed = differenceInMinutes(new Date(), parsedDate);
        if (isToday(parsedDate) && isSameHour(new Date(), parsedDate)) {
            return minutePassed > 5 ? `${minutePassed} minutes ago!` : 'Moments ago!';
        }

        if (isToday(parsedDate)) {
            return minutePassed > 60 ? 'Today' : 'Less than an hour ago!';
        }
        if (isYesterday(parsedDate)) {
            return 'Yesterday';
        }

        return format(parsedDate, 'yyyy/mm/dd');
    };

    return (
        <DataListItem className={notification.notificationStatus.toString() === 'UNREAD' ? 'success' : ''}>
            <div className="header">
                <div className="col-9 title">
                    <div>
                        <i
                            className={`custom-icon icon${
                                notification.notificationStatus.toString() === 'UNREAD' ? ' bg-success' : ''
                            }`}
                            aria-label={`Notification${
                                notification.notificationStatus.toString() === 'UNREAD' ? ' on' : ' off'
                            }`}
                        />
                    </div>
                    <div>
                        <h2>
                            {notification.notificationMessage}
                            <small>{getDate(notification.notificationDate)}</small>
                        </h2>
                    </div>
                </div>
                <div className="col-3 action-col text-right">
                    <Button
                        data-notification={notification.notificationId}
                        data-type={notificationType}
                        data-status={notification.notificationStatus.toString() === 'UNREAD' ? '2' : '1'}
                        onClick={updateNotificationStatusHandler}
                        className={`btn-link no-text${
                            notification.notificationStatus.toString() === 'UNREAD' ? ' btn-delete' : ''
                        }`}
                    >
                        <i
                            className={`custom-icon icon bg-${
                                notification.notificationStatus.toString() === 'UNREAD' ? 'danger' : 'success'
                            }`}
                            aria-label={`Notification${
                                notification.notificationStatus.toString() === 'UNREAD' ? ' off' : ' on'
                            }`}
                        />
                    </Button>
                    <span className="btn-divider light" />
                    <Button
                        data-notification={notification.notificationId}
                        onClick={deleteNotificationHandler}
                        className="btn-link delete-btn no-text"
                    >
                        <i className="custom-icon icon" aria-label="Trash" />
                    </Button>
                </div>
            </div>
        </DataListItem>
    );
};

export default NotificationItem;
