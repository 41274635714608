import { SiteType } from '../../../../@types/sites/site';

export const siteDataTemplate: SiteType = {
    siteId: '',
    siteName: '',
    siteStatus: 1,
    inspectSite: 1,
    groupId: '',
    siteInspectors: [],
    inspectionFrequency: '0',
    orderFulfilment: false,
    orderFulfilmentSite: '',
    tel: '',
    altTel: '',
    email: '',
    unitNo: '',
    complexName: '',
    fullAddress: '',
    country: '',
    province: '',
    city: '',
    suburb: '',
    streetAddress: '',
    postalCode: '',
    latitude: 0,
    longitude: 0,
};
