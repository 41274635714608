import React from 'react';

type TabClickHandlerType = {
    event: React.MouseEvent<HTMLAnchorElement>;
    getActiveTabId?: (activeTabId: string) => void;
};

export const tabClickHandler = ({ event, getActiveTabId }: TabClickHandlerType) => {
    event.preventDefault();
    const targetPanes = event.currentTarget.href.split('/');
    const tabParent = event.currentTarget.offsetParent;
    if (targetPanes.length > 0) {
        const targetPane = targetPanes[targetPanes.length - 1];
        if (getActiveTabId) {
            getActiveTabId(targetPane);
        }

        if (tabParent) {
            tabParent.childNodes.forEach((tabChild) => {
                tabChild.childNodes.forEach((grandChild) => {
                    const grandChildElement = grandChild as HTMLElement;

                    if (
                        grandChildElement &&
                        grandChildElement.tagName &&
                        grandChildElement.tagName.toLowerCase() === 'a'
                    ) {
                        const tabLink = grandChild as HTMLAnchorElement;
                        if (!tabLink.classList.contains('no-effect')) {
                            if (event.currentTarget.href === tabLink.href) {
                                if (!tabLink.classList.contains('active')) {
                                    tabLink.classList.add('active');
                                }
                            } else {
                                tabLink.classList.remove('active');
                            }
                        }
                    }
                });
            });

            const tabContent = tabParent.nextSibling;
            if (tabContent) {
                const htmlTabContent = tabContent as HTMLElement;
                if (htmlTabContent.classList.contains('tab-content')) {
                    tabContent.childNodes.forEach((tabPane) => {
                        const htmlTabPane = tabPane as HTMLElement;
                        if (htmlTabPane && htmlTabPane.classList.contains('tab-pane')) {
                            if (htmlTabPane.id === targetPane) {
                                htmlTabPane.classList.remove('fadeOut');
                                htmlTabPane.classList.add('active', 'show', 'fadeIn');
                            } else {
                                htmlTabPane.classList.remove('active', 'show', 'fadeIn');
                                htmlTabPane.classList.add('fadeOut');
                            }
                        }
                    });
                }
            }
        }
    }
};
