import { useCallback } from 'react';

import { useAppSelector } from '../redux';
import useValidateSession from '../useValidateSession';
import { queryClient, SYNC_TIMEOUT } from '../../config/constants/app';
import { getAuthorizedHttpRequestConfig, sendHttpRequest } from '../../utilities/http';

const useAuthorisedTeamMembers = () => {
    const validateSession = useValidateSession();
    const authenticatedUser = useAppSelector((state) => state.authenticatedUser);

    const fetchingAuthorisedTeamMembers = queryClient.isFetching({ queryKey: ['authorisedTeamMembers'] }) > 0;
    const fetchAuthorisedTeamMembers = useCallback((filterParams?: Record<string, any>) => {
        const getAuthorisedTeamMembers = () =>
            validateSession(authenticatedUser).then((authorizationToken) => {
                const httpRequestConfig = {
                    ...getAuthorizedHttpRequestConfig(authorizationToken.trim()),
                    url: '/team/member/authorised',
                    params: filterParams,
                };

                return sendHttpRequest(httpRequestConfig);
            });

        return queryClient.fetchQuery({
            queryKey: ['teamMembers', 'authorisedTeamMembers'],
            queryFn: getAuthorisedTeamMembers,
            staleTime: SYNC_TIMEOUT
        });
    }, []);

    return { fetchAuthorisedTeamMembers, fetchingAuthorisedTeamMembers };
};

export default useAuthorisedTeamMembers;
