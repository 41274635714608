import React from 'react';

interface CustomDropdownMenuType extends React.HTMLProps<HTMLDivElement> {
    children: React.ReactNode;
}
export const CustomDropdownMenu = React.forwardRef<HTMLDivElement, CustomDropdownMenuType>((props, ref) => {
    const { children, style, className, 'aria-labelledby': labeledBy } = props;
    return (
        <div ref={ref} style={{ ...style }} className={className} aria-labelledby={labeledBy}>
            {React.Children.toArray(children)}
        </div>
    );
});
