import { SupplyOrderType, SupplyOrderRecipientType } from '../../../../@types/supplies/supplyOrder';

export const supplyOrderDataTemplate: SupplyOrderType = {
    orderId: '',
    orderStatus: 1,
    siteId: '',
    driverId: '',
    orderItems: [],
};

export const supplyOrderRecipientDataTemplate: SupplyOrderRecipientType = {
    orderId: '',
    firstName: '',
    lastName: '',
    tel: '',
    email: '',
    comments: '',
    signature: '',
    latitude: 0,
    longitude: 0,
    location: '',
};
