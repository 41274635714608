import React from 'react';
import './DataList.scss';

interface DatalistItemTypes extends React.HTMLProps<HTMLDivElement> {
    expandable?: string;
    tiny?: string;
}

const DataListItem: React.FC<DatalistItemTypes> = (props) => {
    const { className, expandable, tiny, children } = props;
    return (
        <div
            {...props}
            className={`data-list-item-wrapper expandable ${expandable ? 'expandable' : ''} ${
                tiny ? 'tiny' : ''
            } ${className}`}
        >
            {children}
        </div>
    );
};

DataListItem.defaultProps = {
    expandable: undefined,
    tiny: undefined,
};
export default DataListItem;
