import { useCallback } from 'react';
import { toast, ToastType, ToastOptions } from 'react-hot-toast';

const useToastNotification = (): ((
    notificationMessage: string,
    notificationType: ToastType,
    autoDismissNotification?: boolean,
) => void) =>
    useCallback((notificationMessage: string, notificationType: ToastType, autoDismissNotification = true) => {
        if (notificationMessage && notificationMessage.trim().length > 0) {
            const toastOptions = {
                duration: autoDismissNotification ? 4000 : 0,
                position: 'top-center',
            } as ToastOptions;

            switch (notificationType) {
                case 'error':
                    toast.error(notificationMessage, toastOptions);
                    break;
                case 'success':
                    toast.success(notificationMessage, toastOptions);
                    break;
                default:
                    toast(notificationMessage, toastOptions);
            }
        }
    }, []);

export default useToastNotification;
