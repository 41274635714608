/* eslint-disable import/no-cycle */
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useToastNotification } from './notification';
import { AuthenticationType } from '../@types/authentication';

const useValidateSession = () => {
    const navigate = useNavigate();
    const toastNotification = useToastNotification();

    return useCallback(
        (authenticatedUser: AuthenticationType) =>
            new Promise<string>((resolve) => {
                if (!authenticatedUser || authenticatedUser?.authenticationToken === '') {
                    toastNotification('Invalid or expired session, please login.', 'error');
                    setTimeout(() => {
                        navigate('/', { replace: true });
                    }, 350);
                    return;
                }

                resolve(authenticatedUser.authenticationToken);
            }),
        [],
    );
};

export default useValidateSession;
/* eslint-disable import/no-cycle */
