import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import { localStorageMiddleware, reHydrateStore } from './utilities/localStorage';

import cartReducer from './reducers/cart';
import authenticationReducer from './reducers/authentication';
import userNotificationReducer from './reducers/userNotification';
import { supplyItemOptionsReducer, categoryOptionsReducer } from './reducers/supplies';
import { authorisedTeamMemberOptionsReducer, teamOptionsReducer } from './reducers/team';
import { siteOptionsReducer, siteGroupOptionsReducer } from './reducers/sites';

const store = configureStore({
    reducer: {
        cart: cartReducer,
        authenticatedUser: authenticationReducer,
        authorisedMembers: authorisedTeamMemberOptionsReducer,
        userNotifications: userNotificationReducer,
        categoryOptions: categoryOptionsReducer,
        supplyItemOptions: supplyItemOptionsReducer,
        teamOptions: teamOptionsReducer,
        siteOptions: siteOptionsReducer,
        siteGroupOptions: siteGroupOptionsReducer,
    },
    preloadedState: reHydrateStore(),
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(localStorageMiddleware).concat(logger),
});

store.getState();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
