import React, { useEffect } from 'react';
import InputWrapper from './InputWrapper';
import { autoResizeTextArea, onBlur as handleOnBlur, onFocus as handleOnFocus } from './utilities';

type TextAreaTypes = React.HTMLProps<HTMLTextAreaElement>;

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaTypes>((props, ref) => {
    const { id, required, label, name, onChange, disabled, defaultValue, className, rows, onBlur, onFocus, style } =
        props;

    useEffect(() => {
        autoResizeTextArea();
    }, []);

    useEffect(() => {
        autoResizeTextArea();
    }, [defaultValue]);

    const onBlurReducer = (event: React.FocusEvent<HTMLTextAreaElement>) => {
        handleOnBlur(event);
        if (onBlur) {
            onBlur(event);
        }
    };
    const onFocusReducer = (event: React.FocusEvent<HTMLTextAreaElement>) => {
        handleOnFocus(event);
        if (onFocus) {
            onFocus(event);
        }
    };

    return (
        <InputWrapper required={required}>
            {label && <label htmlFor={id || name}>{label}</label>}
            <textarea
                ref={ref}
                id={id || name}
                name={name}
                onFocus={onFocusReducer}
                onBlur={onBlurReducer}
                onChange={onChange}
                disabled={disabled}
                required={required}
                rows={rows}
                style={style}
                className={`form-control ${className}`}
                defaultValue={defaultValue ?? ''}
                data-autoresize="true"
            />
        </InputWrapper>
    );
});

export default TextArea;
