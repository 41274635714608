import React from 'react';

import Button from '../../../Button';
import { TeamPrivilegeType } from '../../../../@types/team/team';

type CartModalFooterType = {
    userPrivileges: TeamPrivilegeType;
    cartModalExitHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
    checkoutHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const CartModalFooter: React.FC<CartModalFooterType> = ({ userPrivileges, cartModalExitHandler, checkoutHandler }) => (
    <div className="form-footer">
        <div className="row">
            <div className="col-4">
                <Button className="btn-default btn-block bg-white" onClick={cartModalExitHandler}>
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        <i className="custom-icon icon mr-auto" aria-label="Close" />
                        <span className="mr-auto">Continue</span>
                    </div>
                </Button>
            </div>
            <div className="col-7 offset-1">
                <Button onClick={checkoutHandler} className="btn-primary btn-block">
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        {userPrivileges.delivery && !userPrivileges.supplies ? (
                            <>
                                <i
                                    className="custom-icon icon mr-auto"
                                    style={{ backgroundColor: 'white' }}
                                    aria-label="Shopping Cart"
                                />
                                <span className="mr-auto">Checkout</span>
                            </>
                        ) : (
                            <>
                                <i
                                    className="custom-icon icon mr-auto"
                                    style={{ backgroundColor: 'white' }}
                                    aria-label="Truck"
                                />
                                <span className="mr-auto">Queue Order for collection</span>
                            </>
                        )}
                    </div>
                </Button>
            </div>
        </div>
    </div>
);

export default CartModalFooter;
