import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SelectSingleOptionType } from '../../../components/FormElements';
import { TeamListType } from '../../../@types/team/team';

const teamOptions = createSlice({
    name: 'Team Options',
    initialState: [] as SelectSingleOptionType[],
    reducers: {
        setTeamOptions: (state, action: PayloadAction<TeamListType[]>) =>
            [...action.payload].map((team) => ({
                value: team.teamId,
                label: team.teamName,
            })),
        deleteTeamOption: (state, action: PayloadAction<string>) =>
            [...state].filter((option) => option.value !== action.payload)
    },
});

export const { deleteTeamOption, setTeamOptions } = teamOptions.actions;
export default teamOptions.reducer;
