import React from 'react';
import InputWrapper from './InputWrapper';
import { onFocus as handleOnFocus, onBlur as handleOnBlur } from './utilities';

interface TextInputTypes extends React.HTMLProps<HTMLInputElement> {
    toggleRequiredClass?: boolean;
}

const TextInput = React.forwardRef<HTMLInputElement, TextInputTypes>((props, ref) => {
    const {
        id,
        type,
        required,
        label,
        name,
        onChange,
        disabled,
        defaultValue,
        className,
        placeholder,
        onBlur,
        onFocus,
        toggleRequiredClass,
    } = props;
    const onBlurReducer = (event: React.FocusEvent<HTMLInputElement>) => {
        handleOnBlur(event);
        if (onBlur) {
            onBlur(event);
        }
    };
    const onFocusReducer = (event: React.FocusEvent<HTMLInputElement>) => {
        handleOnFocus(event);
        if (onFocus) {
            onFocus(event);
        }
    };

    return (
        <InputWrapper className={toggleRequiredClass ? 'toggle-required' : ''} required={required}>
            {label && <label htmlFor={id || name}>{label}</label>}
            <input
                ref={ref}
                id={id || name}
                type={type || 'text'}
                name={name}
                onFocus={onFocusReducer}
                onBlur={onBlurReducer}
                onChange={onChange}
                disabled={disabled}
                required={required}
                className={`form-control ${className}`}
                placeholder={placeholder || ''}
                defaultValue={defaultValue}
            />
        </InputWrapper>
    );
});

TextInput.defaultProps = {
    toggleRequiredClass: false,
};

export default TextInput;
