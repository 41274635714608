import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatISO } from 'date-fns';

import { UserNotificationSyncType } from '../../@types/userNotification';

type UserNotificationSyncUpdateType = {
    syncId: string;
    notificationMessage: string;
};
const userNotification = createSlice({
    name: 'User Notifications',
    initialState: {} as UserNotificationSyncType,
    reducers: {
        updateNotificationSync: (state, action: PayloadAction<UserNotificationSyncUpdateType>) => {
            const updatedNotificationSync = { ...state };
            const { syncId, notificationMessage } = action.payload;

            if (syncId.trim() === '') {
                return state;
            }

            return {
                ...updatedNotificationSync,
                [syncId]: { updatedAt: formatISO(new Date()), notificationMessage },
            };
        },
    },
});

export const { updateNotificationSync } = userNotification.actions;
export default userNotification.reducer;
