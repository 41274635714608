import loaderImage from './static/loader.png';

let ButtonContent = '';
const ProcessingImage = `
    <div class="d-flex flex-fill align-items-center justify-content-center">
        <img alt="processing..." src="${loaderImage}" />
    </div>
`;

/* eslint-disable no-param-reassign */
export const showHttpProcessIndicator = (httpButton?: HTMLButtonElement) => {
    if (httpButton) {
        if (httpButton.classList.contains('inProcess')) {
            httpButton.removeAttribute('disabled');
            httpButton.classList.remove('inProcess');
            httpButton.innerHTML = ButtonContent;
            ButtonContent = '';
            return;
        }

        ButtonContent = httpButton.innerHTML;
        httpButton.classList.add('inProcess');
        httpButton.innerHTML = ProcessingImage;
        httpButton.setAttribute('disabled', 'disabled');
    }
};
/* eslint-disable no-param-reassign */
