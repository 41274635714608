import React from 'react';

import Button from '../../Button';
import { Card } from '../../Card';

type NoNotificationsType = {
    team?: boolean;
    notificationModalExitHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const NoNotifications: React.FC<NoNotificationsType> = ({ team, notificationModalExitHandler }) => (
    <div className="row p-5">
        <div className="col-md-6 offset-md-3">
            <Card className="p-5 bg-transparent border-0">
                <div className="d-flex flex-column align-items-center justify-content-center flex-fill">
                    <i className="custom-icon icon" aria-label="Notification off" style={{ width: 48, height: 48 }} />
                    <p className="hint-text text-center">No{team ? ' team' : ''} notification(s) available.</p>
                    <Button
                        type="button"
                        onClick={notificationModalExitHandler}
                        className="btn-outline-success btn-block btn-rounded"
                    >
                        <div className="d-flex flex-row align-items-center justify-content-center">
                            <i className="custom-icon icon mr-auto pr-2" aria-label="Close" />
                            <span className="mr-auto">Close</span>
                        </div>
                    </Button>
                </div>
            </Card>
        </div>
    </div>
);

NoNotifications.defaultProps = {
    team: false,
};
export default NoNotifications;
