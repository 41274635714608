import React from 'react';
import { Modal } from 'react-bootstrap';

type ModalContainerType = {
    show: boolean;
    closeModal: () => void;
    children: React.ReactNode;
    modalSize?: 'sm' | 'lg' | 'xl';
    modalStyle?: string;
};

const ModalContainer: React.FC<ModalContainerType> = ({ show, closeModal, children, modalSize, modalStyle }) => (
    <Modal size={modalSize} show={show} onHide={closeModal} backdrop="static" className={modalStyle}>
        {children}
    </Modal>
);

ModalContainer.defaultProps = {
    modalSize: 'lg',
    modalStyle: 'slide-right',
};

export default ModalContainer;
