import React from 'react';

type FullPageProgressIndicatorTypes = React.HTMLProps<HTMLDivElement>;

const FullPageProgressIndicator: React.FC<FullPageProgressIndicatorTypes> = (props) => {
    const { style } = props;

    return (
        <div className="loading-wrapper" style={{ ...style }}>
            <div className="progress-indicator-container" />
        </div>
    );
};

export default FullPageProgressIndicator;
