import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { CustomDropdownToggle, CustomDropdownMenu } from '../../../../../../components/DropdownMenu';
import { AuthenticationType } from '../../../../../../@types/authentication';

type UserDropdownMenuType = {
    userLogoutHandler: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    authenticatedUser: AuthenticationType;
};

const UserDropdownMenu: React.FC<UserDropdownMenuType> = ({ userLogoutHandler, authenticatedUser }) => {
    const { firstName, privileges: userPrivileges } = authenticatedUser;
    const smallIconSize = { style: { width: 16, height: 16 } };

    /* const dropdownToggle = (Toggle: React.ComponentType) => {
        return (<Toggle />) as React.ElementType;
    }; */

    return (
        <Dropdown className="d-lg-block p-l-15">
            <Dropdown.Toggle
                as={CustomDropdownToggle as React.ElementType}
                className="profile-dropdown-toggle dropdown-toggle"
                id="dropdown-custom-components"
            >
                <i className="custom-icon icon" aria-label="User" style={{ width: 20, height: 20 }} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu profile-dropdown" as={CustomDropdownMenu as React.ElementType}>
                <Dropdown.Item eventKey="1" href="/profile">
                    <i className="custom-icon icon" aria-label="User" {...smallIconSize} />
                    <span className="d-flex flex-column align-items-start justify-content-around">
                        Hi {firstName}!<span className="hint-text fs-11">Manage your profile</span>
                    </span>
                </Dropdown.Item>
                <Dropdown.Divider />
                {userPrivileges.team ? (
                    <>
                        {/*
                        <Dropdown.Item eventKey="3" href="/settings/team">
                            <i className="custom-icon icon" aria-label="Settings" {...smallIconSize} />
                            Account
                        </Dropdown.Item>
                        */}
                        <Dropdown.Item eventKey="3" href="/settings/team">
                            <i className="custom-icon icon" aria-label="Users" {...smallIconSize} />
                            Team
                        </Dropdown.Item>
                        <Dropdown.Divider />
                    </>
                ) : null}

                <Dropdown.Item className="logout" eventKey="6" onClick={userLogoutHandler}>
                    <span>Logout</span>
                    <i className="custom-icon icon" aria-label="Power" {...smallIconSize} />
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default UserDropdownMenu;
