import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

type RedirectUrlType = {
    redirectURL?: string;
};

const Redirect: React.FC<RedirectUrlType> = ({ redirectURL }) => {
    const location = useLocation();

    return (
        <Routes>
            <Route path={'/*'} element={<Navigate to={`${redirectURL}`} state={{ from: location }} replace />} />
        </Routes>
    );
};

Redirect.defaultProps = {
    redirectURL: '/page-not-found',
};

export default Redirect;
