import { useCallback } from 'react';

import { queryClient, SYNC_TIMEOUT } from '../../config/constants/app';
import { useAppSelector } from '../redux';
import useValidateSession from '../useValidateSession';
import { getAuthorizedHttpRequestConfig, sendHttpRequest } from '../../utilities/http';

const useSiteInspections = () => {
    const validateSession = useValidateSession();
    const authenticatedUser = useAppSelector((state) => state.authenticatedUser);
    const fetchingSiteInspections = queryClient.isFetching({ queryKey: ['siteInspections'] }) > 0;

    const fetchSiteInspections = useCallback((filterParams?: Record<string, any>) => {
        const getSiteInspections = () =>
            validateSession(authenticatedUser).then((authenticationToken) => {
                const httpRequestConfig = {
                    ...getAuthorizedHttpRequestConfig(authenticationToken),
                    url: '/site/inspection',
                    params: filterParams,
                };

                return sendHttpRequest(httpRequestConfig);
            });

        return queryClient.fetchQuery({
            queryKey: ['siteInspections', { params: filterParams }],
            queryFn: getSiteInspections,
            staleTime: SYNC_TIMEOUT
        });
    }, []);

    return { fetchingSiteInspections, fetchSiteInspections };
};

export default useSiteInspections;
