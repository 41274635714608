import { SupplyItemInventoryType } from '../../../../@types/supplies/supplyItemInventory';

export const supplyItemInventoryDataTemplate: SupplyItemInventoryType = {
    orderId: '',
    inventoryId: '',
    inventoryReason: 1,
    inventoryRef: '',
    itemId: '',
    itemQuantity: 0,
    itemPrice: 0,
    taxId: '',
};
