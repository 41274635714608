import { useCallback } from 'react';

import { useAppDispatch } from "../redux";
import { setSiteGroupOptions } from "../../redux/reducers/sites";
import useSiteGroups from './useSiteGroups';
import type { SiteGroupListType } from '../../@types/sites/siteGroup';

const useSiteGroupOptions = () => {
    const reduxDispatch = useAppDispatch();
    const { fetchSiteGroups, fetchingSiteGroups: fetchingSiteGroupOptions } = useSiteGroups();

    const refreshSiteGroupOptions = useCallback(() => {
        fetchSiteGroups().then((response) => {
            const updatedSiteGroups = response?.records as SiteGroupListType[] || [];
            reduxDispatch(setSiteGroupOptions(updatedSiteGroups))
        });
    }, []);

    return { refreshSiteGroupOptions, fetchingSiteGroupOptions };
};

export default useSiteGroupOptions;
