import React, { useState } from 'react';

import Button from '../../../Button';

interface HeaderSearchType extends React.HTMLProps<HTMLInputElement> {
    clearSearchHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const SearchBar = React.forwardRef<HTMLInputElement, HeaderSearchType>((props, ref) => {
    const { clearSearchHandler, defaultValue, onChange } = props;

    const [activateClearSearch, setActivateClearSearch] = useState(false);
    const handleSearchValueUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setActivateClearSearch(event.target.value.toString().trim().length > 0);
        if (onChange) {
            onChange(event);
        }
    };

    const handleClearSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActivateClearSearch(false);
        clearSearchHandler(event);
    };

    if (onChange) {
        return (
            <div id="globalSearchBar" className={`default-search ${activateClearSearch ? 'focused' : ''}`}>
                <div>
                    <i className="custom-icon icon" aria-label="Search" />
                </div>
                <input
                    type="search"
                    ref={ref}
                    placeholder="Search..."
                    defaultValue={defaultValue}
                    onChange={handleSearchValueUpdate}
                />
                <div className="clear-search ">
                    <Button
                        className={`btn-link ${activateClearSearch ? '' : 'disabled'}`}
                        onClick={handleClearSearch}
                        disabled={!activateClearSearch}
                    >
                        <i className="custom-icon icon" aria-label="Close" />
                    </Button>
                </div>
            </div>
        );
    }

    return null;
});

export default SearchBar;
