import React from 'react';

type DefaultButtonType = React.HTMLProps<HTMLButtonElement>;

const DefaultButton = React.forwardRef<HTMLButtonElement, DefaultButtonType>((props, ref) => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line react/button-has-type
    <button {...props} type={props.type || 'button'} className={`btn ${props.className}`} ref={ref}>
        <div className="btn-content">{props.children}</div>
    </button>
));

export default DefaultButton;
