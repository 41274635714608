import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SelectSingleOptionType } from '../../../components/FormElements';
import { SupplyItemListType } from '../../../@types/supplies/supplyItem';

const supplyItemOptions = createSlice({
    name: 'Supply Item Options',
    initialState: [] as SelectSingleOptionType[],
    reducers: {
        setSupplyItemOptions: (state, action: PayloadAction<SupplyItemListType[]>) =>
            [...action.payload].map((item) => ({
                value: item.itemId,
                label: item.itemName,
            })),
        deleteSupplyItemOption: (state, action: PayloadAction<string>) =>
            [...state].filter((option) => option.value !== action.payload)
    },
});

export const { deleteSupplyItemOption, setSupplyItemOptions } = supplyItemOptions.actions;
export default supplyItemOptions.reducer;
