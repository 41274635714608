import { useCallback } from 'react';

import useTeamList from './useTeamList'
import { useAppDispatch } from "../redux";
import { setTeamOptions } from "../../redux/reducers/team";
import type { TeamListType } from '../../@types/team/team';

const useTeamOptions = () => {
    const reduxDispatch = useAppDispatch();
    const { fetchTeams, fetchingTeams: fetchingTeamOptions } = useTeamList();

    const refreshTeamOptions = useCallback(() => {
        fetchTeams().then((response) => {
            const updatedTeamMembers = ((response?.records as TeamListType[]) || []);
            reduxDispatch(setTeamOptions(updatedTeamMembers));
        });
    }, [fetchTeams]);


    return { refreshTeamOptions, fetchingTeamOptions };
};

export default useTeamOptions;
