import React from 'react';
import Select, { Props } from 'react-select';
import { SelectOptionType } from './types';
import CustomSelectStyles from './CustomSelectStyles';
import { handleSelectBlur, handleSelectFocus } from './utilities';

interface ReactSelectTypes<Option> extends Props<Option> {
    label: string;
}

const SelectElement: React.FC<ReactSelectTypes<SelectOptionType>> = (props) => {
    const customSelectStyles = CustomSelectStyles<SelectOptionType>();
    const { label, defaultValue, isDisabled } = props;
    return (
        <div className="form-group form-group-react-select">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="position-absolute" style={{ zIndex: 5, paddingTop: 5, paddingLeft: 10 }}>
                {label}
            </label>
            <div className="controls">
                <Select
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...props}
                    isDisabled={isDisabled}
                    value={defaultValue}
                    styles={customSelectStyles}
                    onFocus={handleSelectFocus}
                    onBlur={handleSelectBlur}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                />
            </div>
        </div>
    );
};
/* const SelectElement: React.FC<reactSelectTypes> = ({ name, onChange, defaultValue, label, options }) => {
    const handleSelectChange = (option: Option | null, actionMeta: ActionMeta<Option>) => {
        onChange({
            target: {
                name: name,
                value: option,
                type: 'select',
            },
        });
    };

    return (
        <div className={'form-group form-group-react-select'}>
            <label>{label}</label>
            <Select<Options<reactSelectOptionTypes>>
                getOptionLabel={(option: reactSelectOptionTypes) => option.label}
                getOptionValue={(option: reactSelectOptionTypes) => option.value}
                defaultValue={defaultValue}
                options={options}
                isClearable={true}
                onChange={handleSelectChange}
            />
        </div>
    );
}; */
export default SelectElement;
