import { useCallback } from 'react';

export type FetchStaticMapImageLocationType = { latitude: number; longitude: number };

const useFetchStaticMapImage = () =>
    useCallback(
        (locations: FetchStaticMapImageLocationType[]) =>
            new Promise<Blob>((resolve, reject) => {
                const apiKey = 'AIzaSyCHTIesNRsbe2Lq6tqE9Rn9Gh4wY4i2qwU';
                const baseMapImageUrl = `//maps.googleapis.com/maps/api/staticmap?zoom=12&size=400x300`;
                const markerColors = ['blue', 'red', 'yellow', 'green'];

                const markers = locations.map((location, index) => {
                    const { latitude, longitude } = location;
                    return `markers=color:${markerColors[index]}%7C${latitude},${longitude}`;
                });
                const staticMapImageUrl = `${baseMapImageUrl}&${markers.join('&')}&key=${apiKey}`;

                fetch(staticMapImageUrl)
                    // Retrieve its body as ReadableStream
                    .then((response) => {
                        const reader = response.body?.getReader();
                        return new ReadableStream({
                            start(controller) {
                                return pump();
                                function pump(): any {
                                    if (reader) {
                                        return reader.read().then(({ done, value }) => {
                                            // When no more data needs to be consumed, close the stream
                                            if (done) {
                                                controller.close();
                                                return;
                                            }
                                            // Enqueue the next data chunk into our target stream
                                            controller.enqueue(value);
                                            // eslint-disable-next-line consistent-return
                                            return pump();
                                        });
                                    }
                                    return null;
                                }
                            },
                        });
                    })
                    // Create a new response out of the stream
                    .then((stream) => new Response(stream))
                    // Create an object URL for the response
                    .then((response) => response.blob())
                    .then((blob) => {
                        resolve(blob);
                    })

                    .catch((err) => {
                        reject(new Error(err));
                    });
            }),
        [],
    );

export default useFetchStaticMapImage;
