import React from 'react';
import { Link } from 'react-router-dom';

import { TabDataType } from './utilities/tabDataType';
import { tabClickHandler } from './utilities/tabClickHandler';

type TabType = {
    className?: string;
    tabData: TabDataType[];
    getActiveTabIdHandler?: (activeTabId: string) => void;
};

const Tabs: React.FC<TabType> = ({ className, getActiveTabIdHandler, tabData }) => {
    const handleTabClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        tabClickHandler({ event, getActiveTabId: getActiveTabIdHandler });
    };

    return (
        <ul className={`nav ${className}`}>
            {tabData.map((tab) => (
                <li className={`nav-item ${tab.align || ''}`} key={tab.id}>
                    <Link to={`/${tab.id}`} onClick={handleTabClick} className={`${tab.active ? 'active' : ''}`}>
                        {tab.title}
                    </Link>
                </li>
            ))}
        </ul>
    );
};

Tabs.defaultProps = {
    className: undefined,
    getActiveTabIdHandler: undefined,
};

export default Tabs;
