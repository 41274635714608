import { useCallback } from 'react';

import { queryClient, SYNC_TIMEOUT } from '../../config/constants/app';
import { useAppSelector } from '../redux';
import useValidateSession from '../useValidateSession';
import { getAuthorizedHttpRequestConfig, sendHttpRequest } from '../../utilities/http';

const useSiteInspectionSchedule = () => {
    const validateSession = useValidateSession();
    const authenticatedUser = useAppSelector((state) => state.authenticatedUser);
    const fetchingSiteInspectionSchedule = queryClient.isFetching({ queryKey: ['siteInspectionSchedule'] }) > 0;

    const fetchSiteInspectionSchedule = useCallback((filterParams?: Record<string, any>) => {
        const getSites = () =>
            validateSession(authenticatedUser).then((authenticationToken) => {
                const httpRequestConfig = {
                    ...getAuthorizedHttpRequestConfig(authenticationToken),
                    url: '/site/inspection/schedule',
                    params: filterParams,
                };

                return sendHttpRequest(httpRequestConfig);
            });

        return queryClient.fetchQuery({
            queryKey: ['siteInspectionSchedule', { params: filterParams }],
            queryFn: getSites,
            staleTime: SYNC_TIMEOUT
        });
    }, []);

    return { fetchingSiteInspectionSchedule, fetchSiteInspectionSchedule };
};

export default useSiteInspectionSchedule;
