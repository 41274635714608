import React from 'react';

interface ModalHeaderType extends React.HTMLProps<HTMLHtmlElement> {
    modalExitHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ModalHeader: React.FC<ModalHeaderType> = ({ children, modalExitHandler }) => (
    <header>
        <div className="header-inner">
            <button type="button" onClick={modalExitHandler}>
                <i className="custom-icon icon mr-auto" aria-label="Close" />
            </button>
            {children}
        </div>
    </header>
);

export default ModalHeader;
