export enum UserNotificationStatusType {
    'UNREAD' = 1,
    'READ',
}

export enum UserNotificationContextType {
    'SUCCESS' = 1,
    'ERROR',
    'WARNING',
    'INFO',
}

export interface UserNotificationType {
    notificationId: string;
    recipients: string[];
    notificationStatus: UserNotificationStatusType;
    notificationType: UserNotificationContextType;
    notificationMessage: string;
    notificationDate: string;
}

export type UserNotificationTypes = Record<string, UserNotificationType[]>;

export type UserNotificationSyncPropType = {
    notificationMessage: string;
    updatedAt: string;
};

export type UserNotificationSyncType = Record<string, UserNotificationSyncPropType>;
