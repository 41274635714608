import React from 'react';
import { Link } from 'react-router-dom';

type AuthenticationPageLayoutHeaderType = {
    btnURL?: string;
    btnText?: string;
};

const AuthenticationPageLayoutHeader: React.FC<AuthenticationPageLayoutHeaderType> = ({ btnURL, btnText }) => (
    <div className="auth-wrapper-header">
        {typeof btnURL !== 'undefined' && typeof btnText !== 'undefined' && (
            <>
                <div className="col-7">
                    <Link to="/">
                        <i className="main-logo" />
                    </Link>
                </div>
                <div className="col-5 text-right text-xs-center">
                    <Link to={`/${btnURL}`} className="btn btn-outline-primary disabled">
                        <i className="rs-icon-lock p-r-10" /> {btnText}
                    </Link>
                </div>
            </>
        )}
        {typeof btnURL === 'undefined' && typeof btnText === 'undefined' && (
            <div className="col-12 text-center">
                <Link to="/">
                    <i className="logo" style={{ width: 150 }} />
                </Link>
            </div>
        )}
    </div>
);

AuthenticationPageLayoutHeader.defaultProps = {
    btnURL: undefined,
    btnText: undefined,
};

export default AuthenticationPageLayoutHeader;
