import React from 'react';

type DividerWithCenteredTextType = React.HTMLProps<HTMLDivElement>;

const DividerWithCenteredText: React.FC<DividerWithCenteredTextType> = ({ children }) => (
    <div className="rs-divider rs-divider-horizontal rs-divider-with-text border-light">
        {children && <span className="rs-divider-inner-text">{children}</span>}
    </div>
);

export default DividerWithCenteredText;
