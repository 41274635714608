import React, { useEffect, useState } from 'react';
import { Props } from 'react-select';

import { useAppSelector } from '../../hooks/redux';
import { ONE_MINUTE } from '../../config/constants/app';
import { SelectInput, SelectSingleOptionType } from '../FormElements';
import { useSiteOptions } from '../../hooks/sites';
import { getSelectedOption } from './getSelectedOption';
import type { SelectOptionType } from '../FormElements/elements/select-element/types';

interface SelectSiteOptionsType<Option> extends Props<Option> {
    selectedOption?: string | null;
    datalistFilter?: boolean;
    orderFulfilmentSites?: boolean;
}

let initSiteOptions = true;

const SelectSiteOptions: React.FC<SelectSiteOptionsType<SelectOptionType>> = (props) => {
    const { datalistFilter, selectedOption, orderFulfilmentSites, name, onChange } = props;
    const { refreshSiteOptions, fetchingSiteOptions } = useSiteOptions();

    const reduxSiteOptions = useAppSelector((state) => state.siteOptions);
    const [siteOptions, setSiteOptions] = useState<SelectSingleOptionType[]>([]);

    useEffect(() => {
        const updatedSiteOptions = reduxSiteOptions as SelectSingleOptionType[] || [];
        setSiteOptions(updatedSiteOptions);
    }, [reduxSiteOptions]);

    useEffect(() => {
        const syncTimer = setInterval(() => {
            refreshSiteOptions(orderFulfilmentSites);
        }, ONE_MINUTE * 3);

        return () => {
            clearInterval(syncTimer);
        };
    }, []);

    useEffect(() => {
        if (initSiteOptions) {
            initSiteOptions = false;
            refreshSiteOptions(orderFulfilmentSites);
        }
    }, [refreshSiteOptions, orderFulfilmentSites]);

    let firstOption = { value: '0', label: 'None' };
    if (datalistFilter) {
        firstOption = { value: '0', label: 'All' };
    }

    const updatedSiteSelectOptions = [{ ...firstOption }, ...siteOptions.slice()];
    const selectedValue = getSelectedOption(updatedSiteSelectOptions, selectedOption);
    const label = orderFulfilmentSites ? 'Order Fulfilment Centre' : 'Site';

    return (
        <SelectInput
            name={name}
            label={label}
            onChange={onChange}
            isLoading={fetchingSiteOptions}
            options={updatedSiteSelectOptions}
            defaultValue={selectedValue}
        />
    );
};

SelectSiteOptions.defaultProps = {
    selectedOption: undefined,
    datalistFilter: false,
    orderFulfilmentSites: false,
};

export default SelectSiteOptions;
