import useValidateSession from '../useValidateSession';
import { useToastNotification } from '../notification';
import { useAppDispatch, useAppSelector } from '../redux';
import { deleteTeamOption } from "../../redux/reducers/team";
import useTeamOptions from "./useTeamOptions";
import { queryClient } from '../../config/constants/app';
import { getAuthorizedHttpRequestConfig, useHttpMutation } from '../../utilities/http';

import type { TeamType, TeamRolePrivileges } from '../../@types/team/team';

const useTeamActions = () => {
    const validateSession = useValidateSession();
    const reduxDispatch = useAppDispatch();
    const { refreshTeamOptions } = useTeamOptions();
    const authenticatedUser = useAppSelector((state) => state.authenticatedUser);

    const toastNotification = useToastNotification();
    const customMutationFn = useHttpMutation(['teams']);
    const teamActionInProgress = queryClient.isMutating({ mutationKey: ['teams'] }) > 0;

    const addUpdateTeam = (teamData: TeamType, actionButton?: HTMLButtonElement) =>
        new Promise<Record<string, any>>((resolve, reject) => {
            validateSession(authenticatedUser)
                .then((authorizationToken) => {
                    const { teamId, teamName, teamDesc, privileges } = teamData;

                    const updatedPrivileges: Record<string, number> = {};
                    Object.keys({ ...privileges }).forEach((privilege) => {
                        updatedPrivileges[privilege] = +privileges[privilege as TeamRolePrivileges];
                    });

                    const httpMethod = teamId.trim() === '' ? 'POST' : 'PATCH';
                    const httpRequestConfig = {
                        ...getAuthorizedHttpRequestConfig(authorizationToken, httpMethod),
                        url: '/team',
                        data: { teamId, teamName, teamDesc, ...updatedPrivileges },
                    };

                    customMutationFn({
                        actionButton,
                        httpRequestConfig,
                        responseHandler: (response) => {
                            resolve(response);
                            queryClient.invalidateQueries({ queryKey: ['teams'], type: 'all' }).then(() => {
                                refreshTeamOptions()
                            });
                        },
                        errorHandler: (error) => {
                            toastNotification(error.message, 'error');
                        },
                    });
                })
                .catch(() => {
                    reject(new Error('Invalid or expired session, please login.'));
                });
        });

    const deleteTeam = (teamId: string, actionButton?: HTMLButtonElement) =>
        new Promise<Record<string, any>>((resolve, reject) => {
            validateSession(authenticatedUser)
                .then((authorizationToken) => {
                    const httpRequestConfig = {
                        ...getAuthorizedHttpRequestConfig(authorizationToken, 'DELETE'),
                        url: '/team',
                        data: { teamId },
                    };

                    customMutationFn({
                        actionButton,
                        httpRequestConfig,
                        responseHandler: (response) => {
                            resolve(response);
                            queryClient.invalidateQueries({ queryKey: ['teams'], type: 'all' }).then(() => {
                                reduxDispatch(deleteTeamOption(teamId));
                            });
                        },
                        errorHandler: (error) => {
                            toastNotification(error.message, 'error');
                        },
                    });
                })
                .catch(() => {
                    reject(new Error('Invalid or expired session, please login.'));
                });
        });

    return { addUpdateTeam, deleteTeam, teamActionInProgress };
};

export default useTeamActions;
