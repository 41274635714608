import React from 'react';

type InputWrapperTypes = React.HTMLProps<HTMLDivElement>;

const InputWrapper: React.FC<InputWrapperTypes> = ({ required, className, children }) => (
    <div className={`form-group form-group-default${required ? ' required' : ''}${className ? ` ${className}` : ''}`}>
        {children}
    </div>
);

export default InputWrapper;
