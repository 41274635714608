import React, { useEffect, useState } from 'react';
import { Props } from 'react-select';

import { useAppSelector } from '../../hooks/redux';
import { ONE_MINUTE } from '../../config/constants/app';
import { SelectInput, SelectSingleOptionType } from '../FormElements';
import { useAuthorisedTeamMemberOptions } from '../../hooks/team';
import { getSelectedOption } from './getSelectedOption';
import type { SelectOptionType } from '../FormElements/elements/select-element/types';

interface SelectDriverOptionsType<Option> extends Props<Option> {
    selectedOption?: string | null;
    datalistFilter?: boolean;
}

let initDriverOptions = true;

const SelectDriverOptions: React.FC<SelectDriverOptionsType<SelectOptionType>> = (props) => {
    const { refreshAuthorisedTeamMemberOptions, fetchingAuthorisedTeamMemberOptions } = useAuthorisedTeamMemberOptions();
    const { datalistFilter, selectedOption, name, onChange } = props;

    const authorisedMembers = useAppSelector((state) => state.authorisedMembers);
    const [driverOptions, setDriverOptions] = useState<SelectSingleOptionType[]>([]);

    useEffect(() => {
        const updatedDrivers = authorisedMembers.filter((driver) => driver.privileges.delivery);
        const updatedDriverOptions = updatedDrivers.map((option) => ({
            value: option.memberId,
            label: `${option.firstName} ${option.lastName}`,
        }));
        setDriverOptions(updatedDriverOptions);
    }, [authorisedMembers]);


    useEffect(() => {
        const syncTimer = setInterval(() => {
            refreshAuthorisedTeamMemberOptions();
        }, ONE_MINUTE * 3);

        return () => {
            clearInterval(syncTimer);
        };
    }, []);

    useEffect(() => {
        if (initDriverOptions) {
            initDriverOptions = false;
            refreshAuthorisedTeamMemberOptions();
        }
    }, [refreshAuthorisedTeamMemberOptions]);

    let firstOption = { value: '0', label: 'None' };
    if (datalistFilter) {
        firstOption = { value: '0', label: 'All' };
    }

    const updatedDriverSelectOptions = [{ ...firstOption }, ...driverOptions.slice()];
    const selectedValue = getSelectedOption(updatedDriverSelectOptions, selectedOption);

    return (
        <SelectInput
            name={name}
            label="Driver"
            onChange={onChange}
            isLoading={fetchingAuthorisedTeamMemberOptions}
            options={updatedDriverSelectOptions}
            defaultValue={selectedValue}
        />
    );
};

SelectDriverOptions.defaultProps = {
    selectedOption: undefined,
    datalistFilter: false,
};

export default SelectDriverOptions;
