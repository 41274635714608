import React from 'react';

interface ComponentProgressIndicatorTypes extends React.HTMLProps<HTMLDivElement> {
    removePadding?: boolean;
}

const ComponentProgressIndicator: React.FC<ComponentProgressIndicatorTypes> = ({ children, removePadding, style }) => {
    const padding = typeof removePadding !== 'undefined' && removePadding ? 70 : 85;

    return (
        <div className="loading-wrapper position-relative animated fadeIn" style={{ ...style }}>
            <div className="progress-indicator-container">
                <div className="loading-indicator" />
                <div style={{ paddingTop: padding }} className="loading-text small-text">
                    {children}
                </div>
            </div>
        </div>
    );
};

ComponentProgressIndicator.defaultProps = {
    removePadding: false,
};
export default ComponentProgressIndicator;
