import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SelectSingleOptionType } from '../../../components/FormElements';
import { SiteListType } from '../../../@types/sites/site';

const siteOptions = createSlice({
    name: 'Site Options',
    initialState: [] as SelectSingleOptionType[],
    reducers: {
        setSiteOptions: (state, action: PayloadAction<SiteListType[]>) => {
            const activeSites = [...action.payload].filter((site) => site.siteStatus.toString() === 'SITE_ACTIVE');
            return activeSites.map((site) => ({
                value: site.siteId,
                label: site.siteName,
            }));
        },

        deleteSiteOption: (state, action: PayloadAction<string>) =>
            [...state].filter((option) => option.value !== action.payload)
    },
});

export const { deleteSiteOption, setSiteOptions } = siteOptions.actions;
export default siteOptions.reducer;
