import { useState, useEffect } from 'react';

const useScreenWidth = (currentScreenWidth: number) => {
    const [screenWidth, setScreenWidth] = useState(currentScreenWidth);

    useEffect(() => {
        const updateScreenWidth = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', updateScreenWidth);
        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, []);

    return screenWidth;
};

export default useScreenWidth;
