import React from 'react';

import Button from '../../../Button';
import DataListItem from '../../../DataListItem';
import { CartItemType } from '../../../../@types/cart';
import { formatCartItemQuantity } from '../../../../utilities/cart/formatCartItemQuantity';

type MobileCartListItemType = {
    cartItem: CartItemType;
    updateItemQuantityHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
    removeItemFromCartHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const MobileCartListItem: React.FC<MobileCartListItemType> = ({
    cartItem,
    updateItemQuantityHandler,
    removeItemFromCartHandler,
}) => (
    <DataListItem>
        <div className="header d-flex flex-row align-items-center justify-content-center">
            <div className="d-flex flex-fill align-items-center">
                <Button
                    data-supply={cartItem.itemId}
                    data-direction="decrease"
                    onClick={
                        cartItem.itemQuantity === cartItem.itemMinimumSaleQuantity
                            ? removeItemFromCartHandler
                            : updateItemQuantityHandler
                    }
                    className="btn-link btn-block no-text"
                >
                    <i className="custom-icon icon bg-danger" aria-label="Minus Circle" />
                </Button>
            </div>
            <div className="col-9">
                <h6 className="text-center m-0">
                    {cartItem.itemName}
                    <small className="d-block m-0">{formatCartItemQuantity(cartItem)}</small>
                </h6>
            </div>
            <div className="d-flex flex-fill align-items-center">
                <Button
                    data-supply={cartItem.itemId}
                    data-direction="increase"
                    onClick={updateItemQuantityHandler}
                    className="btn-link btn-block no-text"
                >
                    <i className="custom-icon icon bg-success" aria-label="Plus Circle" />
                </Button>
            </div>
        </div>
    </DataListItem>
);

export default MobileCartListItem;
