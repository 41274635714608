import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SelectSingleOptionType } from '../../../components/FormElements';
import { SupplyItemCategoryListType } from '../../../@types/supplies/supplyItemCategory';

const categoryOptions = createSlice({
    name: 'Supply Category Options',
    initialState: [] as SelectSingleOptionType[],
    reducers: {
        setCategoryOptions: (state, action: PayloadAction<SupplyItemCategoryListType[]>) =>
            [...action.payload].map((category) => ({
                value: category.categoryId,
                label: category.categoryName,
            })),

        deleteCategoryOption: (state, action: PayloadAction<string>) =>
            [...state].filter((option) => option.value !== action.payload)
    },
});

export const { deleteCategoryOption, setCategoryOptions } = categoryOptions.actions;
export default categoryOptions.reducer;
