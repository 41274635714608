import React, { useEffect, useState } from 'react';

import { NoNotifications, NotificationItem } from './components';
import Tabs from '../Tabs';
import Button from '../Button';
import { ComponentProgressIndicator } from '../ProgressIndicator';
import { VerticalDividerWithCenteredText } from '../Divider';
import ModalContainer, { ModalHeader } from '../../containers/ModalContainer';
import { useToastNotification, useUserNotificationActions } from '../../hooks/notification';
import { UserNotificationType, UserNotificationTypes, UserNotificationStatusType } from '../../@types/userNotification';

type UserNotificationModalType = {
    notifications: UserNotificationTypes;
    updatingNotifications: boolean;
    unreadNotifications: UserNotificationType[];
    openNotificationModal: boolean;
    updateNotificationHandler: () => void;
    closeNotificationModalHandler: () => void;
};

const UserNotifications: React.FC<UserNotificationModalType> = ({
    notifications,
    updatingNotifications,
    unreadNotifications,
    updateNotificationHandler,
    openNotificationModal,
    closeNotificationModalHandler,
}) => {
    const { myNotifications, teamNotifications } = notifications;
    const [allMyNotifications, setAllMyNotifications] = useState<UserNotificationType[]>([]);

    useEffect(() => {
        setAllMyNotifications(myNotifications.concat(teamNotifications));
    }, [myNotifications, teamNotifications]);

    const handleDismissModalHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        closeNotificationModalHandler();
    };

    const NotificationTabs = [
        {
            id: 'myNotificationsTab',
            title: 'For Me',
            active: true,
        },
        {
            id: 'myTeamNotificationsTab',
            title: 'Team',
        },
    ];

    const toastNotification = useToastNotification();
    const { deleteNotification, updateNotificationStatus } = useUserNotificationActions();

    const handleUpdateNotificationStatus = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const notificationType = event.currentTarget.dataset.type;
        const notificationId = event.currentTarget.dataset.notification;
        const actionButton = event.currentTarget;
        if (notificationId && notificationType) {
            if (notificationType in notifications) {
                const notification = notifications[notificationType].find(
                    (item) => item.notificationId === notificationId,
                );
                const notificationStatus = event.currentTarget.dataset.status;
                if (notification && notificationStatus) {
                    const notificationData = {
                        notifications: [notificationId],
                        notificationStatus: +notificationStatus,
                    };
                    updateNotificationStatus(notificationData, actionButton)
                        .then((response) => {
                            if ('success' in response) {
                                updateNotificationHandler();
                            }
                        })
                        .catch(() => {});
                }
            }
        }
    };

    const handleDeleteNotificationStatus = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const actionButton = event.currentTarget;
        const notificationId = event.currentTarget.dataset.notification;
        if (notificationId) {
            deleteNotification([notificationId], actionButton)
                .then((response) => {
                    if ('success' in response) {
                        updateNotificationHandler();
                    }
                })
                .catch(() => {});
        }
    };

    const handleDeleteAllNotifications = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const actionButton = event.currentTarget;

        if (allMyNotifications.length > 0) {
            const allMyNotificationId = allMyNotifications.map((item) => item.notificationId);
            deleteNotification(allMyNotificationId, actionButton)
                .then((response) => {
                    if ('success' in response) {
                        toastNotification(response.success as string, 'success');
                        updateNotificationHandler();
                        closeNotificationModalHandler();
                    }
                })
                .catch(() => {});
        }
    };

    const handleMarkAllNotificationsAsRead = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const actionButton = event.currentTarget;

        if (unreadNotifications.length > 0) {
            const unreadNotificationId = unreadNotifications.map((notification) => notification.notificationId);

            const notificationData = {
                notifications: unreadNotificationId,
                notificationStatus: UserNotificationStatusType.READ,
            };
            updateNotificationStatus(notificationData, actionButton)
                .then((response) => {
                    if ('success' in response) {
                        updateNotificationHandler();
                        toastNotification(`${unreadNotificationId.length} notifications(s) marked as read!`, 'success');
                    }
                })
                .catch(() => {});
        }
    };

    const RenderNotifications = () => {
        if (updatingNotifications) {
            return <ComponentProgressIndicator>Loading notifications...</ComponentProgressIndicator>;
        }

        if (allMyNotifications.length > 0) {
            return (
                <>
                    <Tabs tabData={NotificationTabs} className="nav-tabs nav-tabs-simple nav-tabs-info" />
                    <div className="tab-content p-0 pt-3">
                        <div className="tab-pane fade active show" id="myNotificationsTab">
                            {myNotifications.length > 0 ? (
                                myNotifications.map((notification) => (
                                    <NotificationItem
                                        key={notification.notificationId}
                                        notificationType="myNotifications"
                                        notification={notification}
                                        updateNotificationStatusHandler={handleUpdateNotificationStatus}
                                        deleteNotificationHandler={handleDeleteNotificationStatus}
                                    />
                                ))
                            ) : (
                                <NoNotifications notificationModalExitHandler={handleDismissModalHandler} />
                            )}
                        </div>
                        <div className="tab-pane fade" id="myTeamNotificationsTab">
                            {teamNotifications.length > 0 ? (
                                teamNotifications.map((notification) => (
                                    <NotificationItem
                                        key={notification.notificationId}
                                        notificationType="teamNotifications"
                                        notification={notification}
                                        updateNotificationStatusHandler={handleUpdateNotificationStatus}
                                        deleteNotificationHandler={handleDeleteNotificationStatus}
                                    />
                                ))
                            ) : (
                                <NoNotifications notificationModalExitHandler={handleDismissModalHandler} team />
                            )}
                        </div>
                    </div>
                    <div className="form-footer">
                        <div className="row">
                            <div className="col-md-12">
                                <Button className="btn-default btn-block bg-white" onClick={handleDismissModalHandler}>
                                    <div className="d-flex flex-row align-items-center justify-content-center">
                                        <i className="custom-icon icon mr-auto" aria-label="Close" />
                                        <span className="mr-auto">Close</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            );
        }

        return <NoNotifications notificationModalExitHandler={handleDismissModalHandler} />;
    };

    return (
        <ModalContainer show={openNotificationModal} closeModal={closeNotificationModalHandler}>
            <ModalHeader modalExitHandler={handleDismissModalHandler}>
                <h5 className="mr-auto">Notifications</h5>
                <span className="text-right">
                    <div className="d-flex flex-fill flex-row">
                        <Button
                            onClick={handleMarkAllNotificationsAsRead}
                            disabled={unreadNotifications.length === 0}
                            className={`btn-link ${
                                unreadNotifications.length > 0 && allMyNotifications.length > 0 ? '' : 'disabled'
                            }`}
                        >
                            <i
                                className={`custom-icon icon ${
                                    unreadNotifications.length > 0 && allMyNotifications.length > 0 ? 'bg-danger' : ''
                                }`}
                                aria-label="Notification off"
                            />
                        </Button>
                        <div style={{ height: '90%', width: 10 }}>
                            <VerticalDividerWithCenteredText />
                        </div>
                        <Button
                            onClick={handleDeleteAllNotifications}
                            disabled={allMyNotifications.length === 0}
                            className={allMyNotifications.length > 0 ? 'btn-danger' : 'btn-default disabled'}
                        >
                            <i
                                className="custom-icon icon"
                                style={{ backgroundColor: allMyNotifications.length > 0 ? '#ffffff' : '' }}
                                aria-label="Trash"
                            />
                        </Button>
                    </div>
                </span>
            </ModalHeader>
            <div className="modal-body">{RenderNotifications()}</div>
        </ModalContainer>
    );
};

export default UserNotifications;
