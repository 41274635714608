import { useCallback } from 'react';

import useFetchStaticMapImage, { FetchStaticMapImageLocationType } from './useFetchStaticMapImage';

const useStaticMapImage = () => {
    const fetchStaticMapImage = useFetchStaticMapImage();
    return useCallback(
        (locations: FetchStaticMapImageLocationType[]) =>
            new Promise<string>((resolve) => {
                fetchStaticMapImage(locations).then((blob) => {
                    const fileReader = new FileReader();
                    fileReader.onloadend = () => {
                        const resultSet = (fileReader.result as string).split(',');
                        if (resultSet.length > 0) {
                            const imageDataUrl = `data:image/png;base64,${resultSet[resultSet.length - 1]}`;
                            resolve(imageDataUrl);
                        }
                    };
                    fileReader.readAsDataURL(blob);
                });
            }),
        [fetchStaticMapImage],
    );
};

export default useStaticMapImage;
