import { useEffect, useState } from 'react';
import { useAppSelector } from './redux';

import { authenticatedUserDataTemplate } from '../config/constants/dataTemplates';

const useAuthenticatedUser = () => {
    const [authenticatedUser, setAuthenticatedUser] = useState(authenticatedUserDataTemplate);
    const userDetail = useAppSelector((state) => state.authenticatedUser);
    const [validatingUser, setValidatingUser] = useState(true);
    useEffect(() => {
        setAuthenticatedUser((currentlyAuthenticatedUser) => ({
            ...currentlyAuthenticatedUser,
            ...userDetail,
        }));
        setValidatingUser(false);
    }, [userDetail]);

    return { authenticatedUser, validatingUser };
};

export default useAuthenticatedUser;
