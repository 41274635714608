import type { SelectSingleOptionType } from '../FormElements';

export const getSelectedOption = (
    options: SelectSingleOptionType[],
    selectedOption?: string | null,
    defaultOption?: number,
) => {
    const userSelectedValue = options.find((option) => option.value === (selectedOption?.toString() || ''));

    return userSelectedValue || options[defaultOption || 0];
};
